import * as React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import {
  Menu,
  MenuItem,
  IconButton,
  CircularProgress,
  TableBody,
  Typography,
  TableCell,
  Button,
  Modal,
  TableRow,
} from "@mui/material";
import ReactLoading from "react-loading";
import Chip from "../Chip";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TableCustomLayout from "./TableCustomLayout";
import TableHeadCustom from "./TableHeadCustom";
import TableCellCustom from "./TableCellCustom";
import TableRowCustom from "./TableRowCustom";
import {
  dispatchedEmailReal,
  dispatchedEmailTest,
  cancelDispatch,
} from "../../app/services/EmailService";
import { notifyError, notifySuccess } from "../../utils/toast";
import ModalEmailAlert from "../Modals/ModalEmailAlert";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Descrição",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "subject",
    numeric: true,
    disablePadding: false,
    label: "Objecto",
  },
  {
    id: "dispatchedEmailsCount",
    numeric: true,
    disablePadding: false,
    label: "Enviados",
  },
  {
    id: "mongoQueryCount",
    numeric: true,
    disablePadding: false,
    label: "Total de emails",
  },
  {
    id: "actions",
    numeric: true,
    disablePadding: false,
    label: "",
  },
];

const DEFAULT_ORDER = "desc";
const DEFAULT_ORDER_BY = "description";
const DEFAULT_ROWS_PER_PAGE = 10000;

let visibleRows = [];

const EMAIL_INFO_INIT = {
  emailId: "",
  title: "",
  message: "",
  type: "",
  open: false,
};
export default function TableEmails(props) {
  const navigate = useNavigate();
  const [order, setOrder] = React.useState(DEFAULT_ORDER);
  const [orderBy, setOrderBy] = React.useState(DEFAULT_ORDER_BY);
  const [sendingEmail, setSendingEmail] = React.useState(false);
  const [page] = React.useState(0);
  const [rowsPerPage] = React.useState(DEFAULT_ROWS_PER_PAGE);

  const [modalInfo, setModalInfo] = React.useState(EMAIL_INFO_INIT);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event, id) => {
    setAnchorEl({ ...anchorEl, [id]: event.currentTarget });
  };
  const handleClose = (id) => {
    setAnchorEl({ ...anchorEl, [id]: null });
  };

  React.useEffect(() => {
    let rowsOnMount = stableSort(
      props.rows,
      getComparator(DEFAULT_ORDER, DEFAULT_ORDER_BY)
    );

    rowsOnMount = rowsOnMount.slice(
      0 * DEFAULT_ROWS_PER_PAGE,
      0 * DEFAULT_ROWS_PER_PAGE + DEFAULT_ROWS_PER_PAGE
    );

    visibleRows = rowsOnMount;
  }, [props.rows]);

  const handleRequestSort = React.useCallback(
    (event, newOrderBy) => {
      console.log({ order, orderBy, rows: props.rows });
      const isDesc = orderBy === newOrderBy && order === "desc";
      const toggledOrder = isDesc ? "asc" : "desc";
      setOrder(toggledOrder);
      setOrderBy(newOrderBy);

      const sortedRows = stableSort(
        props.rows,
        getComparator(toggledOrder, newOrderBy)
      );

      console.log({ toggledOrder, newOrderBy });

      visibleRows = sortedRows;
    },
    [props.rows, order, orderBy]
  );

  const handleCancelEmail = async (id) => {
    try {
      const res = await cancelDispatch(id);
      props.refreshData();
      notifySuccess("Email apagado com sucesso!");
      const data = await res.data;
      console.log(data);
    } catch (error) {
      notifyError("Erro ao apagar email!");
      console.log(error);
    }
    toggleModalEmail(false);
  };

  const handleDispatchTest = async (id) => {
    try {
      const res = await dispatchedEmailTest(id);
      props.refreshData();
      notifySuccess("Email enviado com sucesso!");
      const data = await res.data;
      console.log(data);
    } catch (error) {
      notifyError("Erro ao enviar email!");
      console.log(error);
    }

    toggleModalEmail(false);
  };

  const handleDispatchReal = async (id) => {
    try {
      setSendingEmail(true);
      const res = await dispatchedEmailReal(id);
      props.refreshData();
      const data = await res.data;
      notifySuccess("Email enviado com sucesso!");
      console.log(data);
      setSendingEmail(false);
    } catch (error) {
      notifyError("Erro ao enviar email!");
      console.log(error);
    }
    toggleModalEmail(false);
  };

  const toggleModalEmail = (bool, data) => {
    handleClose();
    if (bool) {
      setModalInfo({ open: true, ...data });
    } else {
      setModalInfo(EMAIL_INFO_INIT);
    }
  };

  console.log({ modalInfo });

  return (
    <>
      <TableCustomLayout
        title="Emails"
        data={visibleRows}
        action={props.onAddButton}
        disablePagination
      >
        <TableHeadCustom
          order={order}
          orderBy={orderBy}
          headCells={headCells}
          onRequestSort={handleRequestSort}
        />
        <TableBody>
          {visibleRows && visibleRows.length > 0 && !props.loading ? (
            visibleRows.map((row, index) => {
              const labelId = `enhanced-table-checkbox-${index}`;

              return (
                <TableRowCustom
                  tabIndex={-1}
                  key={row.index}
                  sx={{ cursor: "pointer" }}
                >
                  <TableCellCustom
                    align="start"
                    component="th"
                    id={labelId}
                    scope="row"
                  >
                    {row.description}
                  </TableCellCustom>
                  <TableCellCustom align="start">
                    {row.status === "ACTIVE" ? (
                      <Chip className="win" value={"Ativo"} />
                    ) : row.status === "PROCESSING" ? (
                      <Chip className="pending" value={"Processando"} />
                    ) : row.status === "FINISHED" ? (
                      <Chip className="win" value={"Finalizado"} />
                    ) : (
                      <Chip className="lose" value={"Cancelado"} />
                    )}
                  </TableCellCustom>
                  <TableCellCustom align="start">{row.subject}</TableCellCustom>
                  <TableCellCustom align="start">
                    {row.dispatchedEmailsCount}
                  </TableCellCustom>
                  <TableCellCustom align="start">
                    {row.mongoQueryCount}
                  </TableCellCustom>
                  <TableCellCustom sx={{ maxWidth: 20 }} align="start">
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-controls={open ? "long-menu" : undefined}
                      aria-expanded={open ? "true" : undefined}
                      aria-haspopup="true"
                      onClick={e => handleClick(e, row.id)}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl && anchorEl[row.id]}
                      open={Boolean(anchorEl && anchorEl[row.id])}
                      onClose={() => handleClose(row.id)}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem onClick={() => {
                        console.log("edit", row);
                        navigate(`/emails/dispatch/${row.id}/edit`);
                      }}>
                        Editar
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleClose(row.id);
                          toggleModalEmail(true, {
                            type: "test",
                            emailId: row.id,
                            title: "Testar email",
                            message:
                              "Você está prestes a testar um email. Deseja continuar?",
                          });
                        }}
                      >
                        Testar
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleClose(row.id);
                          toggleModalEmail(true, {
                            type: "send",
                            emailId: row.id,
                            title: "Confirmar envio",
                            message:
                              "Você está prestes a enviar um email para todos os usuários. Deseja continuar?",
                          });
                        }}
                      >
                        Enviar para todos
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          console.log("cancel", row);
                          handleClose(row.id);
                          toggleModalEmail(true, {
                            type: "cancel",
                            emailId: row.id,
                            title: "Cancelar email",
                            message:
                              "Você está prestes a cancelar um email. Deseja continuar?",
                          });
                        }}
                      >
                        Cancelar
                      </MenuItem>
                    </Menu>
                  </TableCellCustom>
                </TableRowCustom>
              );
            })
          ) : (
            <>
              {props.loading && (
                <TableRow>
                  <TableCell rowSpan={3} />
                  <TableCell rowSpan={3} />
                  <TableCell rowSpan={6}>
                    <ReactLoading
                      type={"cylon"}
                      color="#ab47bc"
                      height={100}
                      width={100}
                    />
                  </TableCell>
                  <TableCell rowSpan={3} />
                  <TableCell rowSpan={3} />
                  <TableCell rowSpan={3} />
                </TableRow>
              )}
              {!props.loading && visibleRows && visibleRows.length === 0 && (
                <TableRow>
                  <TableCell rowSpan={3} />
                  <TableCell rowSpan={3} />
                  <TableCell align="start" rowSpan={6}>
                    Nenhum item.
                  </TableCell>
                  <TableCell rowSpan={3} />
                  <TableCell rowSpan={3} />
                  <TableCell rowSpan={3} />
                </TableRow>
              )}
            </>
          )}
        </TableBody>
      </TableCustomLayout>
      <ModalEmailAlert
        open={modalInfo.open}
        title={modalInfo.title}
        message={modalInfo.message}
        onClose={() => toggleModalEmail(false)}
        {...(modalInfo.type === "cancel"
          ? { onConfirm: () => handleCancelEmail(modalInfo.emailId) }
          : modalInfo.type === "test"
          ? { onConfirm: () => handleDispatchTest(modalInfo.emailId) }
          : modalInfo.type === "send"
          ? { onConfirm: () => handleDispatchReal(modalInfo.emailId) }
          : {})}
      />
      {/* <Modal
        show={modalConfirmSendRealEmail}
        onHide={() => toggleModalConfirmSendRealEmail(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirmar envio</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Typography variant="body1" gutterBottom>
            Você está prestes a enviar um email para todos os usuários. Deseja
            continuar?
          </Typography>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => toggleModalConfirmSendRealEmail(false)}
          >
            Cancelar
          </Button>
          <Button
            variant="primary"
            disabled={sendingEmail}
            onClick={handleDispatchReal}
          >
            {sendingEmail ? (
              <CircularProgress sx={{ color: "#fff" }} />
            ) : (
              "Salvar"
            )}
          </Button>
        </Modal.Footer>
      </Modal> */}
    </>
  );
}

TableEmails.propTypes = {
  rows: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  onAddButton: PropTypes.func,
};
