import { Box, Paper, Stack, Typography } from "@mui/material";

export default function Card1({ iconSmall, iconLarge, title, value, backgroundColor = "#0a101400", href }) {
  return (
    <Paper sx={{ p: 2, backgroundColor }}>
      <Stack direction="row" justifyContent="space-between">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
          }}
        >
          {iconSmall}

          <Typography fontWeight={700} fontSize={24}>
            {value}
          </Typography>
        </Box>
        <Box>{iconLarge}</Box>
      </Stack>
      <Typography fontWeight={400} fontSize={14}>
        {title}
      </Typography>
    </Paper>
  );
}
