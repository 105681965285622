import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import numeral from "numeral";
import {
  Box,
  TextField,
  Typography,
  Button,
  Paper,
  Stack,
  Divider,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  Grid,
} from "@mui/material";
import * as Yup from "yup";

import WithdrawConfirmModal from "./WithdrawConfirmModal";
import { notifyError, notifySuccess } from "../../utils/toast";
import NumberFormatCustom from "../Input/NumberFormat";
import {
  withdrawalsRequest,
  withdrawalsRequestCode,
} from "../../app/services/PartnerService";
import { getUserWithdrawalAddress } from "../../app/services/UserService";

const networks = [
  {
    value: "ERC20",
    label: "ERC20",
  },
  {
    value: "TRC20",
    label: "TRC20",
  },
  {
    value: "BEP20",
    label: "BEP20",
  },
];

function WithdrawForm({ balance }) {
  const [receiveAmount, setReceiveAmount] = useState(0);
  const [transactionFee, setTransactionFee] = useState(0);
  const [minimumAmount, setMinimumAmount] = useState(10);
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [errorLimit, setError] = useState("");
  const [withdrawalAddress, setWithdrawalAddress] = useState([]);
  const [code, setCode] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  const formit = useFormik({
    initialValues: {
      withdrawalAddressId:
        withdrawalAddress.length > 0 ? withdrawalAddress[0].id : null,
      network:
        withdrawalAddress.length > 0 ? withdrawalAddress[0].network : null,
      amount: "",
      submit: null,
    },
    validationSchema: Yup.object().shape({
      withdrawalAddressId: Yup.string()
        .max(255)
        .required("Informe o endereço da carteira"),
      amount: Yup.number()
        .min(minimumAmount, `valor mínimo de saque é $${minimumAmount}`)
        .transform((value) => (isNaN(value) ? undefined : value))
        .nullable()
        .required("Informe o valor do saque"),
    }),
    onSubmit: async (
      values,
      { resetForm, setErrors, setStatus, setSubmitting }
    ) => {
      try {
        setModalOpen(false);
        const response = await withdrawalsRequest({
          asset: "USDT",
          withdrawalAddressId: values.withdrawalAddressId,
          amount: parseFloat(values.amount),
          validationCode: code,
        });

        resetForm();
        setStatus({ success: true });
        setSubmitting(false);
        notifySuccess("Solicitação de saque recebida com sucesso!");

        // reload withdrawals

        return window.location.reload();
      } catch (err) {
        setModalOpen(false);
        setStatus({ success: false });
        setErrors({ submit: err });
        notifyError("Código inválido, tente novamente.");
        setSubmitting(false);
      }
    },
  });

  const requestCodeForWithdrawals = async () => {
    try {
      const response = await withdrawalsRequestCode();
      setModalOpen(true);
    } catch (err) {
      console.log("err", err);
    }
  };

  const CalculateIncome = () => {
    console.log("balance", balance);
    const values = formit.values;
    useEffect(() => {
      if (values.amount) {
        const outcome =
          Number(values.amount) -
          Number(values.amount * (transactionFee / 100));
        setReceiveAmount(outcome);

        if (minimumAmount > outcome) {
          setEnableSubmit(false);
          return;
        }
        if (balance >= outcome && outcome > 0) {
          setError("");
          setEnableSubmit(true);
          return;
        }
        setError("Você não pode sacar um valor maior que o seu saldo!");
        setEnableSubmit(false);
      }
    }, [formit.values]);
    return null;
  };

  useEffect(() => {
    setReceiveAmount(0);
    setTransactionFee(0);
    setMinimumAmount(10);
    requestWithdrawalAddress();
  }, [setReceiveAmount, setTransactionFee]);

  useEffect(() => {
    if (withdrawalAddress.length > 0) {
      formit.handleChange({
        target: {
          name: "withdrawalAddressId",
          value: withdrawalAddress[0].id,
        },
      });
      formit.handleChange({
        target: {
          name: "network",
          value: withdrawalAddress[0].network,
        },
      });
    }
  }, [withdrawalAddress]);

  const requestWithdrawalAddress = async () => {
    try {
      const res = await getUserWithdrawalAddress();
      setWithdrawalAddress(res.data);
    } catch (error) {
      console.log();
    }
  };

  const handleMaxValue = () => {
    formit.handleChange({
      target: {
        name: "amount",
        value: balance,
      },
    });
  };

  const changeAddress = (e) => {
    const address = withdrawalAddress.find(
      (item) => item.id === e.target.value
    );
    formit.handleChange({
      target: {
        name: "network",
        value: address.network,
      },
    });
  };

  return (
    <Fragment>
      <Paper sx={{ borderRadius: 2, mb: 4 }}>
        <Stack direction={"column"}>
          <Box sx={{ p: 2 }}>
            <Typography fontWeight={500} fontSize={14}>
              Dados
            </Typography>
          </Box>
          <Divider />
          <Box sx={{ p: 2, textAlign: "center" }}>
            <Typography
              variant="caption"
              sx={{ color: "#9C9C9C" }}
              fontWeight={400}
            >
              Obs. A rede utilizada para saque é a ERC20, caso informem um
              endereço de outra rede, os recursos poderão ser perdidos.
            </Typography>
          </Box>
          <Box sx={{ p: 2 }}>
            <Box sx={{ display: "flex", flexDirection: "row", mb: 2 }}>
              <FormControl fullWidth sx={{ mr: 1 }}>
                <Typography sx={{ mb: 1, fontSize: 12 }}>
                  Endereço da Carteira
                </Typography>
                <Select
                  labelId="select-coin"
                  id="select-coin"
                  name="withdrawalAddressId"
                  value={formit.values.withdrawalAddressId}
                  onChange={(e) => {
                    formit.handleChange(e);
                    changeAddress(e);
                  }}
                >
                  {withdrawalAddress.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.address}
                    </MenuItem>
                  ))}
                </Select>
                {formit.values.withdrawalAddressId === null && (
                  <FormHelperText sx={{ color: "red" }}>
                    Para realizar o saque você precisa cadastrar sua Wallet
                    ERC20 na aba segurança
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl fullWidth sx={{ maxWidth: 100 }}>
                <Typography sx={{ mb: 1, fontSize: 12 }}>Rede</Typography>
                <Select
                  id="select-network"
                  name="network"
                  value={formit.values.network}
                  onChange={(e) => formit.handleChange(e)}
                  disabled={true}
                >
                  {networks.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box>
              <TextField
                error={
                  Boolean(formit.touched.amount && formit.errors.amount) ||
                  Boolean(errorLimit)
                }
                fullWidth
                helperText={
                  (formit.touched.amount && formit.errors.amount) || errorLimit
                }
                label={"Quantidade"}
                name="amount"
                onBlur={formit.handleBlur}
                onChange={formit.handleChange}
                required
                value={formit.values.amount}
                variant="outlined"
                placeholder="0"
                InputProps={{
                  inputComponent: NumberFormatCustom,
                  endAdornment: (
                    <Button variant="text" onClick={handleMaxValue}>
                      <Typography>max</Typography>
                    </Button>
                  ),
                }}
                sx={{ mb: 2 }}
              />
            </Box>
          </Box>
        </Stack>
        <Box sx={{ p: 2 }}>
          <Stack direction="row" justifyContent="space-between">
            <Typography
              sx={{ color: "#9C9C9C", mb: 1 }}
              fontWeight={500}
              fontSize={14}
            >
              Valor mínimo de saque:
            </Typography>
            <Typography color="textPrimary" variant="body2">
              {numeral(minimumAmount).format("0.00")} USDT
            </Typography>
          </Stack>
          {/* <Stack direction="row" justifyContent="space-between">
          <Typography
            sx={{ color: "#9C9C9C", mb: 1 }}
            fontWeight={500}
            fontSize={14}
          >
            {t("fee_per_transaction")}
          </Typography>
          <Typography color="textPrimary" variant="body2">
            {numeral(transactionFee).format("0.00")}
            {" %"}
          </Typography>
        </Stack> */}
          <Stack direction="row" justifyContent="space-between">
            <Typography
              sx={{ color: "#9C9C9C", mb: 1 }}
              fontWeight={500}
              fontSize={14}
            >
              Valor à receber:
            </Typography>
            <Typography color="textPrimary" variant="body2">
              {numeral(receiveAmount).format("0.00")} USDT
            </Typography>
          </Stack>
        </Box>

        <CalculateIncome />

        <Box sx={{ p: 2 }}>
          <Button
            size="large"
            color="primary"
            disabled={
              formit.isSubmitting ||
              !enableSubmit ||
              formit.values.withdrawalAddressId === null
            }
            variant="contained"
            onClick={(e) => requestCodeForWithdrawals()}
            fullWidth
          >
            Solicitar Saque
          </Button>
        </Box>
      </Paper>
      <WithdrawConfirmModal
        value={code}
        onChange={setCode}
        onConfirm={(e) => formit.handleSubmit(e)}
        open={modalOpen}
        onClose={setModalOpen}
      />
    </Fragment>
  );
}

WithdrawForm.propTypes = {
  balance: PropTypes.number,
};

export default WithdrawForm;
