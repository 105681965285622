import React from "react";
import { Container } from "@mui/material";
import EmailForm from "../../../components/Email/EmailForm";

export default function EmailDispatch() {
  return (
    <Container>
      <EmailForm />
    </Container>
  );
}
