import React from "react";
import { Link, useParams } from "react-router-dom";
import {
  Alert,
  Button,
  Container,
  Grid,
  MenuItem,
  Select,
  Snackbar,
  TextField,
} from "@mui/material";

export default function EmailInfo() {
  let { emailId } = useParams();
  return (
    <Container>
      <h3>{emailId}</h3>
    </Container>
  );
}
