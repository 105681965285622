import PropTypes from "prop-types";
import { createContext, useEffect, useReducer } from "react";
// import { verify, JWT_SECRET } from '../utils/jwt';
import axios from "axios";
import { apiGet, apiPost } from "../services/apiService";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  accounts: [],
  statistics: {},
  isAdmin: false,
  isAffiliate: false,
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("accessToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

const setAccountId = (data) => {
  if (data) {
    localStorage.setItem("accountId", data);
    axios.defaults.headers.common.accountId = data;
  } else {
    localStorage.removeItem("accountId");
  }
};

const handlers = {
  SET_IS_INITIALIZED: (state, action) => {
    const { isInitialized } = action.payload;
    return {
      ...state,
      isInitialized,
    };
  },
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user, accounts, isAdmin, isAffiliate, accountId } =
      action.payload;

    return {
      ...state,
      isAuthenticated,
      user,
      accounts,
      isAdmin,
      isAffiliate,
      accountId,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { userRequest } = action.payload;
    return {
      ...state,
      isAuthenticated: false,
      userRequest,
    };
  },
  //
  VERIFY_CODE: (state) => ({ ...state }),
  RESEND_CODE: (state) => ({ ...state }),
  PASSWORD_RECOVERY: (state) => ({ ...state }),
  PASSWORD_RESET: (state) => ({ ...state }),
  SET_ACTIVE_ACCOUNT: (state, action) => {
    const { activeAccount } = action.payload;
    return {
      ...state,
      activeAccount,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({});

const api_url = process.env.REACT_APP_API_BASE_URL;
export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = async () => {
    try {
      const accessToken = window.localStorage.getItem("accessToken");

      if (accessToken) {
        setSession(accessToken);

        const user = await apiGet("/users");
        const accounts = await apiGet("/users/listAccounts");

        const { accountId, isAdmin, isAffiliate } = checkAccounts(accounts);

        let statistics = {};
        // if (isAffiliate) {
        //   statistics = await getSingleUserSummarys({ accountId });
        // }

        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: true,
            user: {
              ...user,
              avatar: `${api_url}/users/avatar/${
                user.id
              }/avatar.jpeg?timestamp=${new Date().getTime()}`,
            },
            accounts,
            statistics,
            isAdmin,
            isAffiliate,
            accountId,
          },
        });
      } else {
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
            user: null,
            accounts: [],
            isAdmin: false,
            isAffiliate: false,
            accountId: "",
          },
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: "INITIALIZE",
        payload: {
          isAuthenticated: false,
          user: null,
          accounts: [],
          isAdmin: false,
          isAffiliate: false,
          accountId: "",
        },
      });
    }

    dispatch({
      type: "SET_IS_INITIALIZED",
      payload: {
        isInitialized: true,
      },
    });
  };

  useEffect(() => {
    initialize();
  }, []);

  const login = async ({ email, password, code2fa, keepLoggedIn }) => {
    const response = await apiPost("/auth/login", {
      email,
      password,
      code2fa,
      keepLoggedIn,
    });

    const { token, accounts } = response;

    setSession(token);

    checkAccounts(accounts);

    console.log("Initializing..");

    window.location.href = "/statistics";
  };

  const logout = async () => {
    setSession(null);
    window.localStorage.removeItem("accountId", "");
    window.localStorage.removeItem("partnerAccountId")
    window.localStorage.removeItem("userRequest")
    dispatch({ type: "LOGOUT" });
  };

  const register = async ({ name, email, password, linkId }) => {
    const userRequest = await apiPost(
      "/users/user-requests",
      {
        name,
        email,
        password,
      },
      { linkId }
    );

    // @todo, entender o dispatch
    await window.localStorage.setItem(
      "userRequest",
      JSON.stringify(userRequest)
    );

    dispatch({
      type: "REGISTER",
      payload: {
        userRequest,
      },
    });
  };

  function checkAccounts(accounts) {
    let isAdmin,
      isAffiliate = false;
    let accountId = "";
    const adminAccount = accounts.find((account) => {
      return account.userRole === "ADMIN";
    });

    const affiliateAccount = accounts.find((account) => {
      return account.userRole === "PARTNER";
    });

    const accountsList = [];

    if (adminAccount) {
      accountsList.push(adminAccount);
      isAdmin = true;
    }
    if (affiliateAccount) {
      accountsList.push(affiliateAccount);
      isAffiliate = true;
      localStorage.setItem("partnerAccountId", affiliateAccount.id)
    }

    if (accountsList.length === 0) {
      logout();
      return;
    }

    accountId = accountsList[0].id;

    setAccountId(accountId);
    console.log("info =>", { accountId, isAdmin, isAffiliate });

    return { accountId, isAdmin, isAffiliate };
  }

  const setActiveAccount = (account) => {
    dispatch({
      type: "SET_ACTIVE_ACCOUNT",
      payload: {
        activeAccount: account,
      },
    });
  };

  const verifyEmailCode = async (username, code) => {
    await apiPost("/users/user-requests/submit", {
      id: username,
      code,
    });
    dispatch({
      type: "VERIFY_CODE",
    });
  };

  const verifyPasswordCode = async (email, code) => {
    await apiPost("/users/password-recovery/verify", {
      userEmail: email,
      code,
    });
  };

  const resendCode = async (username) => {
    const response = await apiPost("/users/user-requests/renovate", {
      id: username,
    });
    dispatch({
      type: "RESEND_CODE",
    });
  };

  const passwordRecovery = async (email) => {
    const response = await apiPost("users/password-recovery", {
      userEmail: email,
    });

    dispatch({
      type: "PASSWORD_RECOVERY",
    });

    return response;
  };

  const passwordReset = async (email, code, newPassword) => {
    const response = await apiPost("/users/password-recovery/submit", {
      userEmail: email,
      code,
      newPassword,
    });

    dispatch({
      type: "PASSWORD_RESET",
    });

    return response;
  };

  const passwordResetVerify = async (userEmail, code, requestId) => {
    const { passwordRecoveryRequest } = await apiPost(
      "/users/password-recovery/verify",
      {
        userEmail,
        requestId,
        code,
      }
    );

    return passwordRecoveryRequest;
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: "JWT",
        login,
        logout,
        register,
        initialize,
        setActiveAccount,
        verifyEmailCode,
        verifyPasswordCode,
        resendCode,
        passwordRecovery,
        passwordReset,
        passwordResetVerify,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
