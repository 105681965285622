import { SvgIcon } from "@mui/material";

export default function RevenueIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M13 21C13 21.5523 13.4491 22.0057 13.998 21.9445C14.8372 21.8509 15.6609 21.6394 16.4442 21.3149C17.5361 20.8626 18.5282 20.1997 19.364 19.364C20.1997 18.5282 20.8626 17.5361 21.3149 16.4442C21.6394 15.6609 21.8509 14.8372 21.9445 13.998C22.0057 13.4491 21.5523 13 21 13L14 13C13.4477 13 13 13.4477 13 14L13 21Z"
        fill={props.color}
        fill-opacity="0.25"
      />
      <path
        d="M21 10.9999C21.5523 10.9999 22.0057 10.5508 21.9445 10.0019C21.8509 9.16262 21.6394 8.33899 21.3149 7.55571C20.8626 6.46378 20.1997 5.47163 19.364 4.6359C18.5282 3.80017 17.5361 3.13724 16.4442 2.68495C15.6609 2.3605 14.8372 2.149 13.9979 2.05536C13.4491 1.99412 13 2.44758 13 2.99986L13 9.99986C13 10.5521 13.4477 10.9999 14 10.9999H21Z"
        fill={props.color}
      />
      <path
        d="M11 3.99988C11 3.4476 10.5509 2.99413 10.002 3.05536C7.98222 3.28066 6.08694 4.18502 4.63604 5.63592C2.94821 7.32375 2 9.61293 2 11.9999C2 14.3868 2.94821 16.676 4.63604 18.3638C6.08694 19.8147 7.98222 20.7191 10.002 20.9444C10.5509 21.0056 11 20.5522 11 19.9999L11 3.99988Z"
        fill={props.color}
        fill-opacity="0.25"
      />
    </SvgIcon>
  );
}
