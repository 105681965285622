import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Container } from "@mui/material";
import EmailForm from "../../../../components/Email/EmailForm";
import { getEmailData } from "../../../services/EmailService";

const INIT_EMAIL_DATA = {
  id: "",
  description: "",
  status: "",
  mongoQuery: "",
  commaSeparatedTestEmails: "",
  subject: "",
  htmlTemplate: "",
  mongoQueryCount: 0,
  dispatchedEmailsCount: 0,
};

export default function EmailEditDispatch() {
  const location = useLocation();

  let { emailId } = useParams();

  const [data, setData] = useState(INIT_EMAIL_DATA);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getEmailData(emailId)
      .then((res) => {
        setLoading(false);
        console.log(res.data);
        setData(res.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, [location]);

  return (
    <Container>
      {loading ? <div>Loading...</div> : <EmailForm emailData={data} />}
    </Container>
  );
}
