import React, { useEffect, useState } from "react";

import { getActiveUsersListStatistics } from "../../services/ReportService";
import Filters from "../../dashboard/Filters";
import { get24HoursAgo } from "../../../utils";
import TableStatistics from "../../../components/Tables/TableStatistics";
import csvDownload from "json-to-csv-export";
import { Container } from "@mui/material";

const INIT_METADATA = {
  numberOfElements: 0,
  totalElements: 0,
  totalPages: 0,
};

export default function ActiveUsers() {
  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateTo, setDateTo] = useState(new Date());
  const [environment, setEnvironment] = useState("REAL");
  const [usersStatistic, setUsersStatistic] = useState([]);
  const [metadata, setMetadata] = useState(INIT_METADATA);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setPage(0);
    fetchUsersStatistic(0);
  }, []);

  const handleChangePage = (page) => {
    setPage(page);
    fetchUsersStatistic(page);
  };

  const handleRequestUsersStatistic = () => {
    setPage(0);
    fetchUsersStatistic(0);
  };

  const fetchUsersStatistic = async (page = 0, size = 100) => {
    try {
      setLoading(true);
      setUsersStatistic([]);
      const res = await getActiveUsersListStatistics({
        page,
        size,
        dateFrom,
        dateTo,
        environment,
      });

      const { data, numberOfElements, totalElements, totalPages } = res.data;
      setUsersStatistic(data);
      setMetadata({ numberOfElements, totalElements, totalPages });
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const requestCsvWithdrawalsDonwload = () => {
    getActiveUsersListStatistics({
      page: 0,
      size: 1000,
      dateFrom: new Date("05-13-2023 00:00:00"),
      dateTo: new Date(),
      environment,
    }).then((it) => {
      console.log(it);
      const { data } = it.data;
      const dataToConvert = {
        data,
        filename: `Usuários ativos ${environment} | ${dateFrom} - ${dateTo}.csv`,
      };

      csvDownload(dataToConvert);
    });
  };

  return (
    <>
      <Filters
        dateFrom={dateFrom}
        dateTo={dateTo}
        setDateFrom={setDateFrom}
        setDateTo={setDateTo}
        environment={environment}
        setEnvironment={setEnvironment}
        onClick={handleRequestUsersStatistic}
        disable={loading}
        loading={loading}
      />
      <TableStatistics
        rows={usersStatistic}
        loading={loading}
        metadata={metadata}
        page={page}
        rowsPerPage={100}
        onPageChange={handleChangePage}
        onDownloadCvs={requestCsvWithdrawalsDonwload}
      />
    </>
  );
}
