import React, { useEffect, useState } from "react";

import { requestSearchUsers } from "../../services/AdminService";
import TableUsersSearch from "../../../components/Tables/TableUsersSearch";
import {
  Button,
  Container,
  Stack,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";

export default function SearchUsers() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [usersSearch, setUsersSearch] = useState([]);

  const handleRequestUsersSearch = async (page = 0) => {
    try {
      setLoading(true);
      setUsersSearch([]);
      const res = await requestSearchUsers({
        email,
      });

      const { data, numberOfElements, totalElements, totalPages } = res.data;

      const users = data.map((d) => {
        {
          return {
            ...d.user,
            accountList: d.accountList,
          };
        }
      });

      console.log("users", users);
      setUsersSearch(users);
      setLoading(false);
    } catch (error) {}
  };

  console.log("email", email);

  return (
    <>
      <Stack
        sx={{ mb: 3, display: "flex" }}
        direction={isMobile ? "column" : "row"}
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <TextField
          label="Email"
          defaultValue="Normal"
          variant="outlined"
          size="medium"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value.toLocaleLowerCase())}
        />
        <Button
          fullWidth={isMobile}
          variant="contained"
          disabled={email !== "" ? false : true}
          onClick={handleRequestUsersSearch}
        >
          Aplicar
        </Button>
      </Stack>
      <TableUsersSearch rows={usersSearch} loading={loading} disablePagination={true}  />
    </>
  );
}
