import React, { useEffect, useState } from "react";

import useAuth from "../../hooks/useAuth";
import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { notifyError, notifySuccess } from "../../../utils/toast";
import {
  deleteWithdrawalAddressById,
  getUserWithdrawalAddress,
  saveWithdrawalAddress,
} from "../../services/UserService";
import Scrollbar from "../../../components/Scrollbar";

const coins = [{ value: "USDT", label: "USDT" }];
const networks = [
  {
    value: "ERC20",
    label: "ERC20",
  },
  {
    value: "TRC20",
    label: "TRC20",
  },
  {
    value: "BEP20",
    label: "BEP20",
  },
];
const columns = ["Endereço", "Asset", "Rede", "Status", "Ação"];

export default function AffiliateSecurity() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [withdrawalAddress, setWithdrawalAddress] = useState([]);
  const [addressValue, setAddressValue] = useState("");
  const [assetSelected, setAssetSelected] = useState(coins[0].value);
  const [networkSelected, setNetworkSelected] = useState(networks[0].value);

  useEffect(() => {
    requestWithdrawalAddress();
  }, []);

  const requestWithdrawalAddress = async () => {
    try {
      const res = await getUserWithdrawalAddress();
      console.log("address", res.data);
      setWithdrawalAddress(res.data);
    } catch (error) {
      console.log();
    }
  };

  const handleAddAddress = async () => {
    try {
      if (!addressValue) {
        notifyError("Por favor, digite um endereço para a carteira");
        return;
      }
      await saveWithdrawalAddress({
        asset: assetSelected,
        address: addressValue,
        network: networkSelected,
      });
      setAddressValue("");
      notifySuccess("Enderrço adicionado com suceso");
      requestWithdrawalAddress();
    } catch (error) {
      console.log();
    }
  };

  const handleDeleteAddress = async (id) => {
    try {
      await deleteWithdrawalAddressById(id);
      notifySuccess("Enderrço excluido com suceso");
      requestWithdrawalAddress();
    } catch (error) {
      console.log();
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2, mb: 2 }}>
            <Typography fontWeight={500} fontSize={14} sx={{ mb: 1 }}>
              Endereço de saques
            </Typography>
            <Stack
              direction={"column"}
              alignItems={"center"}
              sx={{ my: 2 }}
              spacing={2}
            >
              <Grid container spacing={2}>
                <Grid item xs={21} md={6}>
                  <TextField
                    id="wallet_address"
                    name="wallet_address"
                    label={"Endereço da carteira"}
                    value={addressValue}
                    onChange={(e) => setAddressValue(e.target.value)}
                    fullWidth
                    type="text"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={21} md={2}>
                  <FormControl fullWidth sx={{ minWidth: 120 }}>
                    <Select
                      labelId="select-coin"
                      id="select-coin"
                      name="assets"
                      value={assetSelected}
                      onChange={(e) => setAssetSelected(e.target.value)}
                    >
                      {coins.map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={21} md={2}>
                  <FormControl fullWidth sx={{ minWidth: 120 }}>
                    <Select
                      labelId="select-coin"
                      id="select-coin"
                      name="assets"
                      value={networkSelected}
                      onChange={(e) => setNetworkSelected(e.target.value)}
                    >
                      {networks.map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={21} md={2}>
                  <Box sx={{ justifyItems: "end" }}>
                    <Button
                      variant="contained"
                      sx={{ color: "#00DB97", width: "100%", height: 50 }}
                      disabled={!addressValue && true}
                      onClick={() => handleAddAddress()}
                    >
                      <Typography fontWeight={400} fontSize={12} color="white">
                        Adicionar
                      </Typography>
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Stack>
            <Scrollbar>
              <TableContainer
                component={Paper}
                sx={{
                  display: "flex",
                  maxHeight: "auto",
                  width: "100%%",
                }}
              >
                <Table
                  size={`${isMobile ? "small" : "medium"}`}
                  sx={{ width: 1 }}
                >
                  <TableHead sx={{ backgroundColor: "#f4f4f414" }}>
                    <TableRow>
                      {columns.map((item) => (
                        <TableCell key={item}>{item}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody
                    sx={{
                      backgroundColor: "#ffffff0a",
                    }}
                  >
                    {withdrawalAddress.map((item, index) => (
                      <TableRow
                        hover
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                      >
                        <TableCell size="small">
                          <Typography
                            color="textSecondary"
                            variant="subtitle2"
                            sx={{
                              fontSize: isMobile ? 10 : 14,
                            }}
                          >
                            {item.address}
                          </Typography>
                        </TableCell>
                        <TableCell size="small">
                          <Typography
                            color="textSecondary"
                            variant="subtitle2"
                            sx={{
                              fontSize: isMobile ? 10 : 14,
                            }}
                          >
                            {item.asset}
                          </Typography>
                        </TableCell>
                        <TableCell size="small">
                          <Typography
                            color="textSecondary"
                            variant="subtitle2"
                            sx={{
                              fontSize: isMobile ? 10 : 14,
                            }}
                          >
                            {item.network}
                          </Typography>
                        </TableCell>
                        <TableCell size="small">
                          <Typography
                            color="textSecondary"
                            variant="subtitle2"
                            sx={{
                              fontSize: isMobile ? 10 : 14,
                            }}
                          >
                            {new Date().getTime() >=
                            new Date(item.availableFrom).getTime()
                              ? "Liberado"
                              : "Aguardando Liberação"}
                          </Typography>
                        </TableCell>
                        <TableCell size="small">
                          <IconButton
                            color="secondary"
                            onClick={() => handleDeleteAddress(item.id)}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
          </Paper>
        </Grid>
        
      </Grid>
    </>
  );
}
