import { forwardRef } from "react";
import PropTypes from "prop-types";
import { Scrollbars } from "react-custom-scrollbars";

const Scrollbar = forwardRef((props, ref) => {
  const { children, heigth, ...other } = props;
  return (
    <Scrollbars
      ref={ref}
      {...other}
      style={{ height: props.autoHeight ? null : heigth || 250 }}
    >
      {children}
    </Scrollbars>
  );
});

Scrollbar.propTypes = {
  children: PropTypes.node,
  heigth: PropTypes.number,
};

export default Scrollbar;
