import * as React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import ReactLoading from "react-loading";
import { Button, useMediaQuery } from "@mui/material";
import TableCustomLayout from "./TableCustomLayout";
import TableHeadCustom from "./TableHeadCustom";
import TableCellCustom from "./TableCellCustom";
import TableRowCustom from "./TableRowCustom";
import { format } from "date-fns";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "campaignType",
    numeric: false,
    disablePadding: false,
    label: "Tipo de Campanha",
  },
  {
    id: "dataFrom",
    numeric: false,
    disablePadding: false,
    label: "Data Inicial",
  },
  {
    id: "dataTo",
    numeric: false,
    disablePadding: false,
    label: "Data Final",
  },
  {
    id: "bonusType",
    numeric: false,
    disablePadding: false,
    label: "Tipo de Cálculo",
  },
  {
    id: "amount",
    numeric: true,
    disablePadding: false,
    label: "Valor",
  },
  {
    id: "expireInNDays",
    numeric: true,
    disablePadding: false,
    label: "Expira em",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
];

const DEFAULT_ORDER = "desc";
const DEFAULT_ORDER_BY = "amount";
const DEFAULT_ROWS_PER_PAGE = 10;

let visibleRows = [];
export default function TableDepositBonusCampaign(props) {
  const [order, setOrder] = React.useState(DEFAULT_ORDER);
  const [orderBy, setOrderBy] = React.useState(DEFAULT_ORDER_BY);
  const [page] = React.useState(0);
  const [rowsPerPage] = React.useState(DEFAULT_ROWS_PER_PAGE);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  React.useEffect(() => {
    let rowsOnMount = stableSort(
      props.rows,
      getComparator(DEFAULT_ORDER, DEFAULT_ORDER_BY)
    );

    rowsOnMount = rowsOnMount.slice(
      0 * DEFAULT_ROWS_PER_PAGE,
      0 * DEFAULT_ROWS_PER_PAGE + DEFAULT_ROWS_PER_PAGE
    );

    visibleRows = rowsOnMount;
  }, [props.rows]);

  const handleRequestSort = React.useCallback(
    (event, newOrderBy) => {
      console.log({ order, orderBy, rows: props.rows });
      const isDesc = orderBy === newOrderBy && order === "desc";
      const toggledOrder = isDesc ? "asc" : "desc";
      setOrder(toggledOrder);
      setOrderBy(newOrderBy);

      const sortedRows = stableSort(
        props.rows,
        getComparator(toggledOrder, newOrderBy)
      );
      const updatedRows = sortedRows.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      );

      console.log({ toggledOrder, newOrderBy });

      visibleRows = updatedRows;
    },
    [props.rows, order, orderBy]
  );

  console.log("rowsPerPage", props.rowsPerPage);

  return (
    <TableCustomLayout
      title="Campanhas"
      data={visibleRows}
      action={props.onAddButton}
      metadata={props.metadata}
      page={props.page}
      rowsPerPage={props.rowsPerPage}
      onPageChange={props.onPageChange}
    >
      <TableHeadCustom
        order={order}
        orderBy={orderBy}
        headCells={headCells}
        onRequestSort={handleRequestSort}
      />
      <TableBody>
        {visibleRows && visibleRows.length > 0 && !props.loading ? (
          visibleRows.map((row, index) => {
            const labelId = `enhanced-table-checkbox-${index}`;

            return (
              <TableRowCustom
                tabIndex={-1}
                key={row.id}
                sx={{ cursor: "pointer" }}
              >
                <TableCellCustom align="start">
                  {row.campaignType}
                </TableCellCustom>
                <TableCellCustom align="start">
                  {format(new Date(row.from), "dd/MM/yyyy")}
                </TableCellCustom>
                <TableCellCustom align="start">
                  {format(new Date(row.to), "dd/MM/yyyy")}
                </TableCellCustom>
                <TableCellCustom align="start">
                  {row.customParams.amount}
                </TableCellCustom>
                <TableCellCustom align="start">
                  {row.customParams.expireInNDays === 1
                    ? "1 dia"
                    : `${row.customParams.expireInNDays} dias`}
                </TableCellCustom>
                <TableCellCustom align="start">
                  {row.status === "ACTIVE" ? "Ativa" : "Inativa"}
                </TableCellCustom>
                <TableCellCustom align="start">
                  <Button
                    variant="contained"
                    color="warning"
                    onClick={() => {
                      props.toggleUpdate(true, row);
                    }}
                    sx={{ height: 30 }}
                  >
                    Alterar
                  </Button>
                </TableCellCustom>
              </TableRowCustom>
            );
          })
        ) : (
          <>
            {props.loading && (
              <TableRow>
                <TableCell rowSpan={6}>
                  <ReactLoading
                    type={"cylon"}
                    color="#ab47bc"
                    height={100}
                    width={100}
                  />
                </TableCell>
                <TableCell rowSpan={3} />
                <TableCell rowSpan={3} />
              </TableRow>
            )}
            {!props.loading && visibleRows && visibleRows.length === 0 && (
              <TableRow>
                <TableCell align="start" rowSpan={6}>
                  Nenhuma campanha encontrada
                </TableCell>
                <TableCell rowSpan={3} />
                <TableCell rowSpan={3} />
              </TableRow>
            )}
          </>
        )}
      </TableBody>
    </TableCustomLayout>
  );
}

TableDepositBonusCampaign.propTypes = {
  rows: PropTypes.array.isRequired,
  onDownloadCvs: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  metadata: PropTypes.object,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number,
  onPageChange: PropTypes.func,
  onAddButton: PropTypes.func,
  toggleUpdate: PropTypes.func,
};
