import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { Box, Button, Typography } from "@mui/material";

export default function ModalEmailAlert(props) {
  const { title, message } = props;

  return (
    <Modal show={props.open} onHide={props.onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Box>
          <Typography variant="body1">{message}</Typography>
        </Box>
      </Modal.Body>
      <Modal.Footer>
        <Button sx={{ mr: 1 }} variant="contained" onClick={props.onClose}>
          Cancelar
        </Button>
        <Button variant="contained" color="error" onClick={props.onConfirm}>
          Confirmar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

ModalEmailAlert.propTypes = {
  open: PropTypes.bool,
  emailId: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};
