import React, { useContext, useEffect, useState } from "react";
import { useRoutes } from "react-router";
import { withTranslation } from "react-i18next";
import { ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { createTheme } from "../theme";
import routes from "./routes";
import useAuth from "./hooks/useAuth";
import Spinner from "./shared/Spinner";

import pt from "date-fns/locale/pt-BR";
import en from "date-fns/locale/en-US";
import es from "date-fns/locale/es";

import "./App.scss";

const locationDate = {
  br: pt,
  en: en,
  es: es,
};

function App(props) {
  const content = useRoutes(routes);
  const auth = useAuth();

  const darkTheme = createTheme();

  console.log("auth => ", auth.isInitialized);

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={locationDate["pt"]}
    >
      <ThemeProvider theme={darkTheme}>
        {auth?.isInitialized ? content : <Spinner />}
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default withTranslation()(App);
