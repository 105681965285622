import * as React from "react";
import { Theme, useTheme } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { SYMBOLS_AVALIABLE } from "../../utils/constants";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, simbolName, theme) {
  return {
    fontWeight:
      simbolName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function SimbolsChipSeletc({ symbolType, symbols, setSymbols }) {
  console.log("symbolType", symbolType);
  const theme = useTheme();

  const symbolsAvaliable = React.useMemo(() => {
    if (symbolType[0] === "TODOS ATIVOS") {
      return SYMBOLS_AVALIABLE.map((item) => item.symbol);
    }
    return SYMBOLS_AVALIABLE.filter((item) =>
      item.type.includes(symbolType[0])
    ).map((item) => item.symbol);
  }, [symbolType]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSymbols(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <FormControl sx={{ width: "100%" }}>
      <InputLabel id="demo-multiple-chip-label">Simbolos</InputLabel>
      <Select
        labelId="demo-multiple-chip-label"
        id="demo-multiple-tag"
        multiple
        value={symbols || []}
        onChange={handleChange}
        input={<OutlinedInput id="select-multiple-tag" label="Tag" />}
        renderValue={(selected) => selected.join(", ")}
        MenuProps={MenuProps}
      >
        {symbolsAvaliable.map((name) => (
          <MenuItem
            key={name}
            value={name}
            style={getStyles(name, symbols || [], theme)}
          >
            <Checkbox checked={symbols.indexOf(name) > -1} />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
