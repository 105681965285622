import { format } from "date-fns";

export const get24HoursAgo = () => {
  var d = new Date();
  d.setDate(d.getDate() - 1);
  return d;
};

export const convertToUSFormatString = (date) => {
  return format(date, "yyyy-MM-dd");
};
