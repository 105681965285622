import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Button } from "@mui/material";
import TableDepositBonusCampaign from "../../components/Tables/TableDepositBonusCampaign";
import ModalNewCampaigns from "../../components/Modals/ModalNewCampaigns";
import ModalUpdateCampaigns from "../../components/Modals/ModalUpdateCampaigns";
import {
  getCampaigns,
  addNewCampaign,
  updateCampaign,
} from "../services/AdminService";

const INIT_METADATA = {
  numberOfElements: 0,
  totalElements: 0,
  totalPages: 0,
  pageSize: 10,
};
export default function Campaigns() {
  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [metadata, setMetadata] = useState(INIT_METADATA);
  const [page, setPage] = useState(0);
  const [campaignToUpdate, setCampaignToUpdate] = useState(null);

  const [showModalNewCampaigns, setShowModalNewCampaigns] = useState(false);
  const [showModalUpdateCampaigns, setShowModalUpdateCampaigns] =
    useState(false);

  const toggleOpenModalUpdateCampaigns = (open, campaign) => {
    setCampaignToUpdate(campaign);
    setShowModalUpdateCampaigns(open);
  };

  useEffect(() => {
    handleRequestCampaigns();
  }, []);

  const handleChangePage = (page) => {
    setPage(page);
    fetchCampaigns(page);
  };

  const handleRequestCampaigns = () => {
    setPage(0);
    fetchCampaigns(0);
  };

  const fetchCampaigns = (page = 0, size = 10) => {
    setLoading(true);
    getCampaigns({ page, size }).then((it) => {
      console.log(it);
      const { data, numberOfElements, totalElements, totalPages, pageSize } =
        it.data;
      setCampaigns(data);
      setMetadata({
        numberOfElements,
        totalElements,
        totalPages,
        pageSize,
      });
      setLoading(false);
      console.log("dasdas", it.data.length === 0);
    });
  };

  const handleAddNewCampaign = async ({
    campaignType,
    bonusAmountType,
    dateFrom,
    dateTo,
    amount,
    minDepositValueToActivate,
    maxAmount,
    allowMultipleUsages,
    expireInNDays,
    volumeMultiplicationFactor,
    partnerReferralIdToShare,
  }) => {
    try {
      await addNewCampaign({
        campaignType,
        bonusAmountType,
        dateFrom,
        dateTo,
        amount,
        minDepositValueToActivate,
        maxAmount,
        allowMultipleUsages,
        expireInNDays,
        volumeMultiplicationFactor,
        partnerReferralIdToShare,
      });
      setShowModalNewCampaigns(false);
      handleRequestCampaigns();
    } catch (error) {
      setShowModalNewCampaigns(false);
    }
  };

  const handleUpdateCampaign = async ({
    campaignId,
    campaignType,
    bonusAmountType,
    dateFrom,
    dateTo,
    amount,
    minDepositValueToActivate,
    maxAmount,
    allowMultipleUsages,
    expireInNDays,
    status,
    volumeMultiplicationFactor,
    partnerReferralIdToShare,
  }) => {
    try {
      await updateCampaign({
        campaignId,
        campaignType,
        bonusAmountType,
        dateFrom,
        dateTo,
        amount,
        minDepositValueToActivate,
        maxAmount,
        allowMultipleUsages,
        expireInNDays,
        status,
        volumeMultiplicationFactor,
        partnerReferralIdToShare,
      });
      setShowModalUpdateCampaigns(false);
      handleRequestCampaigns();
    } catch (error) {
      setShowModalUpdateCampaigns(false);
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item sx={12} md={12}>
          <TableDepositBonusCampaign
            rows={campaigns}
            onAddButton={
              <Button
                variant="contained"
                sx={{ height: 36 }}
                onClick={() => setShowModalNewCampaigns(true)}
              >
                Nova campanha
              </Button>
            }
            toggleUpdate={toggleOpenModalUpdateCampaigns}
            loading={loading}
            metadata={metadata}
            page={page}
            rowsPerPage={metadata.pageSize}
            onPageChange={handleChangePage}
          />
        </Grid>
      </Grid>
      <ModalNewCampaigns
        open={showModalNewCampaigns}
        onAddCampaign={handleAddNewCampaign}
        onClose={() => setShowModalNewCampaigns(false)}
      />
      <ModalUpdateCampaigns
        open={showModalUpdateCampaigns}
        campaign={campaignToUpdate}
        onUpdateCampaign={handleUpdateCampaign}
        onClose={() => {
          setShowModalUpdateCampaigns(false);
          setCampaignToUpdate(null);
        }}
      />
    </>
  );
}
