import axios from "axios";
import { getBaseUrl } from "./BaseService";
import { convertToUSFormatString } from "../../utils";

export function requestSearchUsers({ email }) {
  var finalUrl = getBaseUrl() + `/admin/users/search?text=${email}&size=10000`;
  return axios.get(finalUrl);
}

export function verifyUser(userId) {
  var finalUrl = getBaseUrl() + `/admin/users/kyc/${userId}/verify`;
  return axios.put(finalUrl, {});
}

export function enableKyc() {
  var finalUrl = getBaseUrl() + "/admin/parameters/enableKyc";
  return axios.put(finalUrl, {});
}

export function disableKyc() {
  var finalUrl = getBaseUrl() + "/admin/parameters/disableKyc";
  return axios.put(finalUrl, {});
}

export function getLimitedUser({ page, size }) {
  var finalUrl =
    getBaseUrl() + `/admin/order/listLimitedUsers?page=${page}&size=${size}`;
  return axios.get(finalUrl, {});
}

export function addLiquidityLimitUser({ email, liquidityLimit }) {
  var finalUrl = getBaseUrl() + "/admin/order/addLiquidityLimit";
  return axios.post(finalUrl, { email, liquidityLimit });
}

export function saveRandomizedBookParamsDTO({ config }) {
  var finalUrl = getBaseUrl() + "/admin/parameters/saveRandomizedBookParamsDTO";
  return axios.put(finalUrl, { ...config });
}

export function getScopedRandomizedBookParams() {
  var finalUrl = getBaseUrl() + "/admin/parameters/scopedRandomizedBookParams";
  return axios.get(finalUrl, {});
}

export function saveNewScopedRandomizedBookParams({
  environment,
  symbol,
  candleTimeFrame,
  randomizedBookParamsDTO: {
    numberOfSimulatedUsers,
    timeRangeFromInSecs,
    timeRangeToInSecs,
    amountFrom,
    amountTo,
  },
}) {
  var finalUrl = getBaseUrl() + "/admin/parameters/scopedRandomizedBookParams";
  return axios.put(finalUrl, {
    environment,
    symbol,
    candleTimeFrame,
    randomizedBookParamsDTO: {
      numberOfSimulatedUsers,
      timeRangeFromInSecs,
      timeRangeToInSecs,
      amountFrom,
      amountTo,
    },
  });
}

export function deleteNewScopedRandomizedBookParams({
  environment,
  symbol,
  candleTimeFrame,
}) {
  var finalUrl =
    getBaseUrl() +
    `/admin/parameters/scopedRandomizedBookParams/${environment}/${symbol}/${candleTimeFrame}`;
  return axios.delete(finalUrl, {});
}

export function getCampaigns({ page, size }) {
  var finalUrl = getBaseUrl() + `/admin/campaign/?page=${page}&size=${size}&sortAttributeName=createdAt&sortMode=DESC`;
  return axios.get(finalUrl, {});
}

export function addNewCampaign({
  campaignType,
  bonusAmountType,
  dateFrom,
  dateTo,
  amount,
  maxAmount,
  minDepositValueToActivate,
  allowMultipleUsages,
  expireInNDays,
  volumeMultiplicationFactor,
  partnerReferralIdToShare
}) {
  const url = "/admin/campaign/createDepositBonusCampaign";
  var finalUrl = getBaseUrl() + url;

  const formData = new FormData();
  formData.append("campaignType", campaignType);
  formData.append("bonusAmountType", bonusAmountType);
  formData.append("dateFrom", convertToUSFormatString(dateFrom));
  formData.append("dateTo", convertToUSFormatString(dateTo));
  formData.append("amount", amount);
  formData.append("maxAmount", maxAmount);
  formData.append("minDepositValueToActivate", minDepositValueToActivate);
  formData.append("allowMultipleUsages", allowMultipleUsages);
  formData.append("expireInNDays", expireInNDays);
  formData.append("volumeMultiplicationFactor", volumeMultiplicationFactor);
  formData.append("partnerReferralIdToShare", partnerReferralIdToShare);
  return axios.post(finalUrl, formData);
}

export function updateCampaign({
  campaignId,
  campaignType,
  bonusAmountType,
  dateFrom,
  dateTo,
  amount,
  maxAmount,
  minDepositValueToActivate,
  allowMultipleUsages,
  expireInNDays,
  status,
  volumeMultiplicationFactor,
  partnerReferralIdToShare
}) {
  var finalUrl = getBaseUrl() + `/admin/campaign/${campaignId}`;

  const formData = new FormData();
  formData.append("campaignType", campaignType);
  formData.append("bonusAmountType", bonusAmountType);
  formData.append("dateFrom", convertToUSFormatString(dateFrom));
  formData.append("dateTo", convertToUSFormatString(dateTo));
  formData.append("amount", amount);
  formData.append("maxAmount", maxAmount);
  formData.append("minDepositValueToActivate", minDepositValueToActivate);
  formData.append("allowMultipleUsages", allowMultipleUsages);
  formData.append("expireInNDays", expireInNDays);
  formData.append("status", status);
  formData.append("volumeMultiplicationFactor", volumeMultiplicationFactor);
  formData.append("partnerReferralIdToShare", partnerReferralIdToShare);
  return axios.put(finalUrl, formData);
}