import React, { useContext, useEffect, useState } from "react";
import {
  Redirect,
  Link as RouterLink,
  useLocation,
  useNavigate,
} from "react-router-dom";
import * as Yup from "yup";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  FormHelperText,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { getBaseUrl } from "../services/BaseService";
import { Formik } from "formik";
import axios from "axios";

import Logo from "../../components/Logo";

import BgImage from "../../assets/images/bg_f_1.png";
import useAuth from "../hooks/useAuth";

const Confirmation2FCode = () => {
  const { login } = useAuth();
  const [userRequest, setUserRequest] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  // const { enqueueSnackbar } = useSnackbar();

  const [isResend, setIsResend] = useState(false);
  const [timer, setTimer] = useState(60);
  const [timeReady, setTimeReady] = useState(false);

  let interval;

  const otpTimer = () => {
    interval = window.setTimeout(() => {
      setTimer(timer - 1);
    });

    clearTimeout(interval);
  };

  const calculateTimer = (expiresAt = null) => {
    if (!expiresAt) {
      return (window.location.href = "/login");
    }

    const now = new Date().getTime();
    const expireTime = new Date(expiresAt).getTime();

    const difference = (expireTime - now) / 1000;

    if (difference >= 0) {
      setTimer(Math.floor(difference));
      setTimeReady(true);
      return;
    }
    setTimer(0);
    setTimeReady(true);
  };

  useEffect(() => {
    const userRequest = localStorage.getItem("userRequest");
    setUserRequest(JSON.parse(userRequest));
  }, []);

  useEffect(() => {
    if (!timeReady) {
      return () => {};
    }

    if (isResend === false) {
      interval = window.setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
    }

    if (timer === 0) {
      setIsResend(true);
      clearTimeout(interval);
    }
    return () => {};
  }, [isResend, timer]);

  useEffect(() => {
    if (userRequest) {
      calculateTimer(userRequest?.emailConfirmationTokenExpirationDate);
    }
  }, [setTimer, setUserRequest]);

  const resendOTP = async (e) => {
    e.preventDefault();

    // @todo update location aqui pra ele pegar o estado novo.
    const res = await axios.post(
      getBaseUrl() + "/users/user-requests/renovate",
      {
        id: userRequest?.id,
      }
    );

    const _userRequest = res.data;

    setIsResend(false);
    setUserRequest(_userRequest);
    calculateTimer(_userRequest.emailConfirmationTokenExpirationDate);
    otpTimer();
  };

  return (
    <Box
      sx={{
        backgroundImage: `url(${BgImage})`,
        backgroundColor: "background.default",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <Container maxWidth="sm" sx={{ py: 10 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mb: 8,
          }}
        />
        <Card sx={{ backgroundColor: "background.default" }}>
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              p: 4,
            }}
          >
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
                mb: 3,
              }}
            >
              <div>
                <Logo
                  sx={{
                    height: 70,
                    width: "auto",
                  }}
                />

                <Typography
                  sx={{ mt: 2 }}
                  color="textSecondary"
                  variant="body2"
                >
                  Insira abaixo o código para autenticação de dois fatores (2FA)
                </Typography>
              </div>
            </Box>
            <Formik
              initialValues={{
                email: location.state?.email || "",
                password: location.state?.password || "",
                code2fa: "",
                submit: null,
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email("E-mail não é válido")
                  .max(255)
                  .required("E-mail é obrigatório"),
                password: Yup.string().max(255).required("Senha obrigatória"),
                code2fa: Yup.string()
                  .min(6, "Código deve ter pelo menos 6 caracteres")
                  .max(6, "Código deve ter no máximo 6 caracteres")
                  .required("Código é obrigatório"),
              })}
              onSubmit={async (
                values,
                { setErrors, setStatus, setSubmitting }
              ) => {
                try {
                  await login({
                    email: values.email,
                    password: values.password,
                    code2fa: values.code2fa,
                  });
                  window.location.href = "/statistics"
                } catch (err) {
                  console.error(err);
                  setStatus({ success: false });
                  setErrors({ submit: err.message ? err.message : err });
                  setSubmitting(false);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                touched,
                values,
              }) => (
                <Stack spacing={2}>
                  <TextField
                    id="code2fa"
                    label="Código"
                    variant="outlined"
                    error={Boolean(touched.code2fa && errors.code2fa)}
                    fullWidth
                    helperText={touched.code2fa && errors.code2fa}
                    margin="normal"
                    name="code2fa"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="code2fa"
                    value={values.code2fa}
                  />
                  {Boolean(
                    Array.isArray(touched.code2fa) &&
                      touched.code2fa.length === 6 &&
                      errors.code2fa
                  ) && (
                    <FormHelperText error sx={{ mx: "14px" }}>
                      {Array.isArray(errors.code2fa) &&
                        errors.code2fa.find((x) => x !== undefined)}
                    </FormHelperText>
                  )}
                  {errors.submit && (
                    <Box sx={{ mt: 3 }}>
                      <FormHelperText error>{errors.submit}</FormHelperText>
                    </Box>
                  )}
                  <Button
                    color="primary"
                    size="large"
                    disabled={isSubmitting}
                    variant="contained"
                    sx={{ mt: 5 }}
                    onClick={(e) => handleSubmit(e)}
                  >
                    Confirmar
                  </Button>
                </Stack>
              )}
            </Formik>

            <Link
              color="textSecondary"
              component={RouterLink}
              to="/"
              variant="body2"
              sx={{ mt: 2 }}
            >
              Voltar ao Login
            </Link>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default Confirmation2FCode;
