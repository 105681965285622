import React from "react";
import {
  Box,
  Button,
  Chip,
  IconButton,
  Modal,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import { createStyles, makeStyles } from "@mui/styles";
import { Close } from "@mui/icons-material";
import { useNavigate } from "react-router";

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      maxWidth: 350,
    },
    chip: {
      width: 18,
      height: 18,
      backgroundColor: "rgba(145, 158, 171, 0.24)",
      borderRadius: 0.51,
      alignContent: "center",
      justifyContent: "center",
      textAlign: "center",
    },
  })
);

function WithdrawConfirmModal({ open, value, onChange, onConfirm, onClose }) {
  const { t } = useTranslation("dashboard");
  const classes = useStyles();

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={open}
      onClose={() => onClose(!open)}
    >
      <Paper sx={{ p: 3 }} className={classes.paper}>
        <Stack direction={"column"} spacing={2}>
          <IconButton
            aria-label="close"
            onClick={() => onClose(!open)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>

          <Stack direction={"row"} spacing={1}>
            <VerifiedUserIcon />
            <Typography>Confirmação</Typography>
          </Stack>
          <Stack direction={"row"} spacing={2}>
            <Typography>
              Digite o código de 6 dígitos enviado por e-mail
            </Typography>
          </Stack>
          <TextField
            id="code"
            name="code"
            label={"Código"}
            placeholder="Digite o código de 6 digitos..."
            value={value}
            onChange={(e) => onChange(e.target.value)}
            fullWidth
            type="text"
            variant="outlined"
          />
          <Stack direction={"row"} justifyContent="space-between" spacing={2}>
            <Button
              variant="contained"
              color="error"
              onClick={() => onClose(!open)}
            >
              Cancelar
            </Button>
            <Button
              disabled={value === ""}
              variant="contained"
              onClick={onConfirm}
            >
              Confirmar
            </Button>
          </Stack>
        </Stack>
      </Paper>
    </Modal>
  );
}

WithdrawConfirmModal.propTypes = {
  open: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
};

export default WithdrawConfirmModal;
