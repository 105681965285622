import { FC, useEffect, useMemo, useRef, useState } from "react";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import numeral from "numeral";
import { cpf as cpfValidator, cnpj as cnpjValidator } from "cpf-cnpj-validator";
import * as EmailValidator from "email-validator";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import InputMask from "react-input-mask";
import WithdrawConfirmModal from "./WithdrawConfirmModal";
import NumberFormatCustom from "../Input/NumberFormat";
import useAuth from "../../app/hooks/useAuth";
import { apiGet, apiPost } from "../../app/services/apiService";
import { notifyError, notifySuccess } from "../../utils/toast";
import { StripEverythingExceptNumbers } from "../../utils/stripChars";

const WithdrawPixForm = (props) => {
  const { balance } = props;
  const { parameters } = useAuth();
  const [errorLimit, setErrorLimit] = useState("");
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [receiveAmount, setReceiveAmount] = useState(0);
  const [validationCode, setValidationCode] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [pixTypeKey, setPixTypeKey] = useState("pix_type_key_cpf");
  const [convertedAmount, setConvertedAmount] = useState("-");
  const [valueDebounceTimer, setValueDebounceTimer] = useState(null);
  const pixKeyRef = useRef();
  const minimumAmount = 20;
  const transactionFee = 0;

  const convertCurrency = async (amount) => {
    return await apiGet("/bank/conversionRate", {
      symbol: "BRLUSDT",
      amount,
    });
  };

  const formik = useFormik({
    initialValues: {
      pixKey: "",
      amount: "",
      submit: null,
    },
    validationSchema: Yup.object().shape({
      pixKey: Yup.string()
        .test("pixKeyInvalid", "Chave PIX inválida.", () => {
          if (pixTypeKey === "pix_type_key_cpf") {
            return cpfValidator.isValid(
              StripEverythingExceptNumbers(formik.values.pixKey)
            );
          }

          if (pixTypeKey === "pix_type_key_cnpj") {
            return cnpjValidator.isValid(
              StripEverythingExceptNumbers(formik.values.pixKey)
            );
          }

          if (pixTypeKey === "pix_type_key_phone") {
            return (
              StripEverythingExceptNumbers(formik.values.pixKey).length === 13
            );
          }

          if (pixTypeKey === "pix_type_key_email") {
            return EmailValidator.validate(formik.values.pixKey);
          }

          return true;
        })
        .required("Chave PIX obrigatória."),
      amount: Yup.number()
        .min(minimumAmount, `valor mínimo de saque é ${minimumAmount}`)
        .transform((value) => (isNaN(value) ? undefined : value))
        .nullable()
        .required("Informe o valor do saque"),
    }),
    async onSubmit(values, { resetForm, setErrors, setStatus, setSubmitting }) {
      try {
        let pixKey = values.pixKey;

        if (
          pixTypeKey === "pix_type_key_cpf" ||
          pixTypeKey === "pix_type_key_cnpj"
        ) {
          pixKey = StripEverythingExceptNumbers(values.pixKey);
        }

        if (pixTypeKey === "pix_type_key_phone") {
          pixKey = `+${StripEverythingExceptNumbers(values.pixKey)}`;
        }

        await apiPost("partner/withdrawals", {
          asset: "USDT",
          pixKey,
          amount: parseFloat(values.amount),
          validationCode,
        });

        resetForm();
        setStatus({ success: true });
        setSubmitting(false);
        notifySuccess("Solicitação de saque recebida com sucesso!");
        return window.location.reload();
      } catch (err) {
        setModalOpen(false);
        setStatus({ success: false });
        setErrors({ submit: err });
        notifyError("Código inválido, tente novamente.");
        setSubmitting(false);
      }
    },
  });

  const CalculateIncome = () => {
    console.log("balance", balance);
    const values = formik.values;
    useEffect(() => {
      if (values.amount) {
        const outcome =
          Number(values.amount) -
          Number(values.amount * (transactionFee / 100));
        setReceiveAmount(outcome);

        if (balance >= outcome && outcome > 0) {
          setErrorLimit("");
          setEnableSubmit(true);
          return;
        }

        setErrorLimit("Você não pode sacar um valor maior que o seu saldo!");
        setEnableSubmit(false);
      }
    }, [formik.values]);
    return null;
  };

  const handleMaxValue = async () => {
    formik.handleChange({
      target: {
        name: "amount",
        value: balance,
      },
    });

    const converted = await convertCurrency(balance);
    setConvertedAmount(converted.convertedValue);
  };

  const requestCodeForWithdrawals = async (e) => {
    e.preventDefault();

    try {
      await apiPost("partner/withdrawals/requestCode", {});
      setModalOpen(true);
    } catch (err) {
      console.log("err", err);
    }
  };

  const pixKeyMask = useMemo(() => {
    switch (pixTypeKey) {
      case "pix_type_key_cpf":
        return "999.999.999-99";
      case "pix_type_key_cnpj":
        return "99.999.999/9999-99";
      case "pix_type_key_phone":
        return "+99 (99) 9 9999-9999";
      default:
        return "";
    }
  }, [pixTypeKey]);

  useEffect(() => {
    if (pixTypeKey === "pix_type_key_phone") {
      pixKeyRef.current?.setSelectionRange(5, 5);
      pixKeyRef.current?.focus();
    }
  }, [pixTypeKey]);

  return (
    <>
      <Paper sx={{ borderRadius: 2, mb: 4 }}>
        <Stack
          component="form"
          direction={"column"}
          onSubmit={requestCodeForWithdrawals}
        >
          <Box sx={{ p: 2 }}>
            <Typography fontWeight={500} fontSize={14}>
              Dados
            </Typography>
          </Box>
          <Divider />
          <Box sx={{ p: 2 }}>
            <FormControl sx={{ mb: 2 }}>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Tipo de chave PIX
              </FormLabel>
              <RadioGroup
                row
                value={pixTypeKey}
                onChange={(_, newValue) => {
                  setPixTypeKey(newValue);

                  if (newValue === "pix_type_key_phone") {
                    formik.handleChange({
                      target: {
                        name: "pixKey",
                        value: "+55",
                      },
                    });
                  } else {
                    pixKeyRef.current?.focus();
                    formik.handleChange({
                      target: {
                        name: "pixKey",
                        value: "",
                      },
                    });
                  }
                }}
              >
                <FormControlLabel
                  value="pix_type_key_cpf"
                  control={<Radio />}
                  label="CPF"
                />
                <FormControlLabel
                  value="pix_type_key_cnpj"
                  control={<Radio />}
                  label="CNPJ"
                />
                <FormControlLabel
                  value="pix_type_key_email"
                  control={<Radio />}
                  label="Email"
                />
                <FormControlLabel
                  value="pix_type_key_phone"
                  control={<Radio />}
                  label="Telefone celular"
                />
                <FormControlLabel
                  value="pix_type_key_random"
                  control={<Radio />}
                  label="Aleatória"
                />
              </RadioGroup>
            </FormControl>
            <Typography color="aqua" sx={{ mb: 2 }}>
              Saque por PIX está em BETA.
            </Typography>
            <InputMask
              value={formik.values.pixKey}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              mask={pixKeyMask}
              maskChar="_"
              ref={(element) => {
                pixKeyRef.current =
                  element?.getInputDOMNode() || pixKeyRef.current;
              }}
            >
              {() => (
                <TextField
                  error={
                    Boolean(formik.touched.pixKey && formik.errors.pixKey) ||
                    Boolean(errorLimit)
                  }
                  fullWidth
                  helperText={formik.touched.pixKey && formik.errors.pixKey}
                  label="Chave PIX"
                  variant="outlined"
                  required
                  name="pixKey"
                  value={pixKeyMask === "" ? formik.values.pixKey : undefined}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  sx={{ mb: 2 }}
                />
              )}
            </InputMask>
            <TextField
              error={
                Boolean(formik.touched.amount && formik.errors.amount) ||
                Boolean(errorLimit)
              }
              fullWidth
              helperText={
                (formik.touched.amount && formik.errors.amount) || errorLimit
              }
              label="Quantidade"
              name="amount"
              onBlur={(e) => {
                formik.handleBlur(e);
              }}
              onChange={(e) => {
                formik.handleChange(e);

                if (valueDebounceTimer) clearTimeout(valueDebounceTimer);

                if (isNaN(Number(e.target.value))) {
                  setConvertedAmount("-");
                  return;
                }

                setValueDebounceTimer(
                  setTimeout(async () => {
                    const converted = await convertCurrency(e.target.value);
                    setConvertedAmount(converted.convertedValue);
                  }, 300)
                );
              }}
              required
              value={formik.values.amount}
              variant="outlined"
              placeholder="0"
              InputProps={{
                inputComponent: NumberFormatCustom,
                endAdornment: (
                  <Button variant="text" onClick={handleMaxValue}>
                    <Typography>Max</Typography>
                  </Button>
                ),
              }}
              sx={{ mb: 2 }}
            />
          </Box>
          <Box sx={{ p: 2 }}>
            <Stack direction="row" justifyContent="space-between">
              <Typography
                sx={{ color: "#9C9C9C", mb: 1 }}
                fontWeight={500}
                fontSize={14}
              >
                Valor mínimo de saque:
              </Typography>
              <Typography color="textPrimary" variant="body2">
                {numeral(minimumAmount).format("0.00")}{" "}
                {parameters?.DEFAULT_COIN?.value}
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography
                sx={{ color: "#9C9C9C", mb: 1 }}
                fontWeight={500}
                fontSize={14}
              >
                Valor à receber:
              </Typography>
              <Typography color="textPrimary" variant="body2">
                {" "}
                {!isNaN(Number(convertedAmount))
                  ? `~ ${numeral(convertedAmount).format("0.00")}`
                  : convertedAmount}{" "}
                BRL
              </Typography>
            </Stack>
          </Box>
          <Box sx={{ p: 2 }}>
            <Button
              size="large"
              color="primary"
              disabled={formik.isSubmitting || !enableSubmit}
              variant="contained"
              fullWidth
              type="submit"
            >
              Solicitar Saque
            </Button>
          </Box>
        </Stack>
        <CalculateIncome />
      </Paper>
      <WithdrawConfirmModal
        value={validationCode}
        onChange={setValidationCode}
        onConfirm={(e) => formik.handleSubmit(e)}
        open={modalOpen}
        onClose={setModalOpen}
      />
    </>
  );
};

export default WithdrawPixForm;
