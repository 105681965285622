import * as React from "react";
import PropTypes from "prop-types";
import { alpha, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ReactLoading from "react-loading";
import numeral from "numeral";
import Chip from "../Chip";
import {
  Collapse,
  IconButton,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import TableCustomLayout from "./TableCustomLayout";
import TableHeadCustom from "./TableHeadCustom";
import TableCellCustom from "./TableCellCustom";
import TableRowCustom from "./TableRowCustom";
import { format } from "date-fns";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "Email",
  },
  {
    id: "accountList",
    numeric: true,
    disablePadding: false,
    label: "Nº de contas",
  },
  {
    id: "createdAt",
    numeric: true,
    disablePadding: false,
    label: "Criado em",
  },
];

const DEFAULT_ORDER = "desc";
const DEFAULT_ORDER_BY = "email";
const DEFAULT_ROWS_PER_PAGE = 10000;

const host = window.location.host.includes("localhost")
  ? `http://${window.location.host}`
  : `https://${window.location.host}`;

let visibleRows = [];

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCellCustom>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCellCustom>
        <TableCellCustom component="th" scope="row">
          {row.email}
        </TableCellCustom>
        <TableCellCustom align="left">{row.accountList.length}</TableCellCustom>
        <TableCellCustom align="left">{row.createdAt}</TableCellCustom>
      </TableRow>
      <TableRow>
        <TableCellCustom style={{ padding: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Typography
                sx={{ pl: 2, pr: 2 }}
                variant="h6"
                gutterBottom
                component="div"
              >
                Contas
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRowCustom>
                    <TableCellCustom>ID</TableCellCustom>
                    <TableCellCustom>Ambiente</TableCellCustom>
                    <TableCellCustom>Regra</TableCellCustom>
                    <TableCellCustom>Criado em</TableCellCustom>
                  </TableRowCustom>
                </TableHead>
                <TableBody>
                  {row.accountList.map((historyRow) => (
                    <TableRowCustom key={historyRow.id}>
                      <TableCellCustom component="th" scope="row">
                        <a
                          href={`${host}/users/${historyRow.id}`}
                          target="_blank"
                        >
                          {historyRow.id}
                        </a>
                      </TableCellCustom>
                      <TableCellCustom>
                        {historyRow.environment}
                      </TableCellCustom>
                      <TableCellCustom align="left">
                        {historyRow.userRole}
                      </TableCellCustom>
                      <TableCellCustom align="left">
                        {format(new Date(row.createdAt), "dd/MM/yyyy hh:MM")}
                      </TableCellCustom>
                    </TableRowCustom>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCellCustom>
      </TableRow>
    </React.Fragment>
  );
}

export default function TableUsersSearch(props) {
  const [order, setOrder] = React.useState(DEFAULT_ORDER);
  const [orderBy, setOrderBy] = React.useState(DEFAULT_ORDER_BY);
  const [page] = React.useState(0);
  const [rowsPerPage] = React.useState(DEFAULT_ROWS_PER_PAGE);
  const [open, setOpen] = React.useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  React.useEffect(() => {
    let rowsOnMount = stableSort(
      props.rows,
      getComparator(DEFAULT_ORDER, DEFAULT_ORDER_BY)
    );

    rowsOnMount = rowsOnMount.slice(
      0 * DEFAULT_ROWS_PER_PAGE,
      0 * DEFAULT_ROWS_PER_PAGE + DEFAULT_ROWS_PER_PAGE
    );

    visibleRows = rowsOnMount;
  }, [props.rows]);

  const handleRequestSort = React.useCallback(
    (event, newOrderBy) => {
      console.log({ order, orderBy, rows: props.rows });
      const isDesc = orderBy === newOrderBy && order === "desc";
      const toggledOrder = isDesc ? "asc" : "desc";
      setOrder(toggledOrder);
      setOrderBy(newOrderBy);

      const sortedRows = stableSort(
        props.rows,
        getComparator(toggledOrder, newOrderBy)
      );
      const updatedRows = sortedRows.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      );

      console.log({ toggledOrder, newOrderBy });

      visibleRows = updatedRows;
    },
    [props.rows, order, orderBy]
  );

  return (
    <TableCustomLayout
      title="Resultado"
      data={visibleRows}
      metadata={props.metadata}
      page={props.page}
      rowsPerPage={props.rowsPerPage}
      onPageChange={props.onPageChange}
      disablePagination={props.disablePagination}
    >
      <TableHeadCustom
        order={order}
        orderBy={orderBy}
        headCells={headCells}
        onRequestSort={handleRequestSort}
        emptyCall={true}
      />
      <TableBody>
        {visibleRows && visibleRows.length > 0 && !props.loading ? (
          visibleRows.map((row, index) => <Row key={row.email} row={row} />)
        ) : (
          <>
            {props.loading && (
              <TableRow>
                <TableCellCustom rowSpan={3} />
                <TableCellCustom rowSpan={6}>
                  <ReactLoading
                    type={"cylon"}
                    color="#ab47bc"
                    height={100}
                    width={100}
                  />
                </TableCellCustom>
                <TableCellCustom rowSpan={3} />
              </TableRow>
            )}
            {!props.loading && visibleRows && visibleRows.length === 0 && (
              <TableRow>
                <TableCellCustom rowSpan={3} />
                <TableCellCustom align="start" rowSpan={6}>
                  Nenhum item.
                </TableCellCustom>
                <TableCellCustom rowSpan={3} />
              </TableRow>
            )}
          </>
        )}
      </TableBody>
    </TableCustomLayout>
  );
}

TableUsersSearch.propTypes = {
  rows: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  metadata: PropTypes.object,
  page: PropTypes.number.isRequired,
  rowsPerPage:  PropTypes.number,
  onPageChange: PropTypes.func.isRequired,
  disablePagination: PropTypes.bool,
};
