import * as React from "react";
import PropTypes from "prop-types";
import { TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";

export default function TableHeadCustom(props) {
  const { order, orderBy, headCells, onRequestSort, align = "start" } = props;
  const createSortHandler = (newOrderBy) => (event) => {
    onRequestSort(event, newOrderBy);
  };

  return (
    <TableHead>
      <TableRow>
        {props.emptyCall && <TableCell />}
        {headCells.map((headCell, index) => (
          <TableCell
            key={index}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            align={align}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "desc"}
              onClick={createSortHandler(headCell.id)}
              style={{ marginRight: align === "center" ? -22 : null }}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

TableHeadCustom.propTypes = {
  headCells: PropTypes.array.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  align: PropTypes.string,
};
