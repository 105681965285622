import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Alert,
  Button,
  Box,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  OutlinedInput,
  Select,
} from "@mui/material";
import {
  createDispatch,
  editDispatch,
} from "../../app/services/EmailService.js";
import { notifyError, notifySuccess } from "../../utils/toast.js";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function EmailForm(props) {
  const navigate = useNavigate();
  console.log("props", props);
  const [data, setData] = useState(
    props.emailData || {
      subject: "",
      description: "",
      commaSeparatedTestEmails: "",
      mongoQuery: "",
      emailName: "Bem-vindo",
    }
  );

  const {
    id,
    subject,
    description,
    commaSeparatedTestEmails,
    mongoQuery,
    htmlTemplate,
  } = data;

  useEffect(() => {
    if (props.emailData) {
      setData(props.emailData);
    }
  }, [props.emailData]);

  const hendleSubmit = async () => {
    let result = null;
    console.log("data", data);
    try {
      const formData = new FormData();
      Object.keys(data).forEach((key) => {
        if (key === "id") {
          formData.append("emailDispatchId", data[key]);
        }
        formData.append(key, data[key]);
      });
      if (id) {
        result = await editDispatch(formData);
      } else {
        result = await createDispatch(formData);
      }
      if (result) {
        notifySuccess("Email criado com sucesso");
        navigate("/emails");
      }
    } catch (error) {
      notifyError("Erro ao criar email");
    }
  };

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { m: 1, width: "25ch" },
      }}
      noValidate
      autoComplete="off"
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <div>
              <label htmlFor="environment">Assunto</label>
            </div>
            <OutlinedInput
              variant="outlined"
              id="subject"
              value={subject}
              onChange={(e) => setData({ ...data, subject: e.target.value })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <div>
              <label htmlFor="environment">Descrição</label>
            </div>
            <OutlinedInput
              variant="outlined"
              id="description"
              value={description}
              onChange={(e) =>
                setData({ ...data, description: e.target.value })
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <div>
              <label htmlFor="environment">
                {" "}
                Lista de emails (separado por virgula)
              </label>
            </div>
            <OutlinedInput
              variant="outlined"
              id="commaSeparatedTestEmails"
              value={commaSeparatedTestEmails}
              onChange={(e) =>
                setData({ ...data, commaSeparatedTestEmails: e.target.value })
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <div>
              <label htmlFor="environment">Query mongo</label>
            </div>
            <OutlinedInput
              id="mongoQuery"
              multiline
              rows={4}
              value={mongoQuery}
              onChange={(e) => setData({ ...data, mongoQuery: e.target.value })}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <div>
              <label htmlFor="environment">Email HTML</label>
            </div>
            <OutlinedInput
              id="htmlTemplate"
              name="htmlTemplate"
              multiline
              rows={4}
              value={htmlTemplate}
              onChange={(e) =>
                setData({ ...data, htmlTemplate: e.target.value })
              }
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={hendleSubmit}>
            {props.emailData ? "Salvar" : "Criar"}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

EmailForm.propTypes = {
  emailData: PropTypes.object,
};
