import axios from "axios";
import { getBaseUrl } from "./BaseService";

export function requestValidateToken(token, successCallback, errorCallBack) {
  var bodyFormData = new FormData();
  bodyFormData.append("token", token);
  return axios
    .post(getBaseUrl() + "/auth/validateToken", bodyFormData)
    .then((res) => successCallback())
    .catch((err) => errorCallBack());
}

export function getEmailsList() {
  let path = `/admin/marketing/email/dispatch`;
  var finalUrl = getBaseUrl() + path;
  return axios.get(finalUrl);
}

export function getEmailData(emailId) {
  let path = `/admin/marketing/email/dispatch/${emailId}`;
  var finalUrl = getBaseUrl() + path;
  return axios.get(finalUrl);
}

export function dispatchedEmailTest(emailId) {
  let path = `/admin/marketing/email/doDispatchTestRepositories?emailDispatchId=${emailId}`;
  var finalUrl = getBaseUrl() + path;
  return axios.post(finalUrl, {});
}

export function dispatchedEmailReal(emailId) {
  let path = `/admin/marketing/email/doDispatchRealRepositories?emailDispatchId=${emailId}`;
  var finalUrl = getBaseUrl() + path;
  return axios.post(finalUrl, {});
}

export function createDispatch(data) {
  let path = `/admin/marketing/email/createDispatch`;
  var finalUrl = getBaseUrl() + path;
  return axios.post(finalUrl, data);
}

export function editDispatch(data) {
  let path = `/admin/marketing/email/editDispatch`;
  var finalUrl = getBaseUrl() + path;
  return axios.put(finalUrl, data);
}

export function cancelDispatch(emailId) {
  let path = `/admin/marketing/email/cancelDispatch?emailDispatchId=${emailId}`;
  var finalUrl = getBaseUrl() + path;
  return axios.delete(finalUrl, {});
}
