import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./app/App";
import "./i18n";
import * as serviceWorker from "./serviceWorker";
import AppContextProvider from "./app/contexts/AppContextProvider";
import { AuthProvider } from "./app/contexts/AuthContext";

ReactDOM.render(
  <AppContextProvider>
    <BrowserRouter basename="/">
      <AppContextProvider>
        <AuthProvider>
          <App />
        </AuthProvider>
      </AppContextProvider>
    </BrowserRouter>
  </AppContextProvider>,

  document.getElementById("root")
);

serviceWorker.unregister();
