import axios from "axios";
import { formatErrorMessage } from "../facades/formatError";

const makeApi = () => {
  if (typeof window !== "undefined") {
    const token = localStorage.getItem("accessToken") ?? null;

    const headers = token
      ? {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      : {
          "Content-type": "application/json",
        };

    return axios.create({
      baseURL: process.env.REACT_APP_API_BASE_URL,
      headers,
      timeout: 30000,
    });
  }

  return null;
};

async function request(url, method, params, headers = {}) {
  try {
    const accountId = window.localStorage.getItem("accountId");

    const response = await makeApi().request({
      url,
      method,
      params: method === "get" ? params : null,
      data: method !== "get" ? params : null,
      headers: {
        accountId: accountId?.replaceAll('"', ""),
        ...headers,
      },
    });

    return response.data;
  } catch (err) {
    const error = err;
    console.log(error);
    if (
      error.response?.status === 401 &&
      error.response?.data?.message !== "Invalid2faCodeException"
    ) {
      if (localStorage.getItem("accessToken")) {
        localStorage.removeItem("accessToken");
        window.location.href = "/login";
      }
    }
    throw formatErrorMessage(err);
  }
}

export async function apiGet(url, params = {}) {
  return request(url, "get", params);
}

export async function apiPost(url, body, headers = {}) {
  return request(url, "post", body, headers);
}

export async function apiPut(url, body) {
  return request(url, "put", body);
}

export async function apiDelete(url, body) {
  return request(url, "delete", body);
}
