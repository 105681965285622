
import PropTypes from "prop-types";
import React from "react";
import { NumericFormat } from "react-number-format";

function NumberFormatCustom(props) {
  const { inputRef, onChange, decimalScale, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: parseInt(values.value),
          },
        });
      }}
      isNumericString
      decimalScale={decimalScale || 8}
      allowLeadingZeros
      allowNegative={false}
      thousandSeparator={false}
      prefix="USDT "
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  decimalScale: PropTypes.number,
};

export default NumberFormatCustom;
