import { Suspense, lazy } from "react";
import GuestGuard from "../components/Guards/GuestGuard";
import NotFound from "./NotFound";
import Campaigns from "./campaigns";
import Emails from "./emails";
import EmailDispatch from "./emails/dispatch";
import EmailEditDispatch from "./emails/dispatch/edit";
import EmailInfo from "./emails/info";
import AffiliateSecurity from "./links/security";
import AffiliateWithdrawal from "./links/withdrawal";
import Spinner from "./shared/Spinner";
import Confirmation2FCode from "./user-pages/Confirmation2FCode";
import ActiveUsers from "./users/active";
import SearchUsers from "./users/search";

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<Spinner />}>
      <Component {...props} />
    </Suspense>
  );

const DashboardLayout = Loadable(
  lazy(() => import("./shared/DashboardLayout"))
);
const AuthGuard = Loadable(
  lazy(() => import("../components/Guards/AuthGuard"))
);
const Dashboard = Loadable(lazy(() => import("./dashboard/Dashboard")));
const Parameters = Loadable(lazy(() => import("./parameters")));
const Simulator = Loadable(lazy(() => import("./parameters/simulator")));
const Order = Loadable(lazy(() => import("./order/Order")));
const Transaction = Loadable(
  lazy(() => import("./withdrawal/transactions/Transaction"))
);
const Balance = Loadable(lazy(() => import("./balance")));
const AffiliateLinks = lazy(() => import("./links"));
const Affiliates = lazy(() => import("./affiliates"));
const Statistics = Loadable(lazy(() => import("./statistics")));
const UserStatistics = Loadable(lazy(() => import("./users/statistics")));
const ReferralUserStatistics = Loadable(lazy(() => import("./users/referral")));
const Users = Loadable(lazy(() => import("./users")));
const Withdrawal = Loadable(lazy(() => import("./withdrawal/Withdrawal")));
const Deposit = Loadable(lazy(() => import("./deposit/Deposit")));
const CreditsAndDebits = Loadable(
  lazy(() => import("./deposit/CreditsAndDebits"))
);
const MyAccount = Loadable(lazy(() => import("./my-account")));
const Login = Loadable(lazy(() => import("./user-pages/Login")));
const Register = Loadable(lazy(() => import("./user-pages/Register")));
const ConfirmationEmailCode = Loadable(
  lazy(() => import("./user-pages/ConfirmationEmailCode"))
);
const PasswordRecovery = Loadable(
  lazy(() => import("./user-pages/PasswordRecovery"))
);
const ConfirmationPasswordCode = Loadable(
  lazy(() => import("./user-pages/ConfirmationPasswordCode"))
);
const ChangePassword = Loadable(
  lazy(() => import("./user-pages/ChangePassword"))
);
const Error500 = Loadable(lazy(() => import("./error-pages/Error500")));
const Error404 = Loadable(lazy(() => import("./error-pages/Error404")));

const routes = [
  {
    path: "*",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: (
          <AuthGuard>
            <Statistics />
          </AuthGuard>
        ),
      },
      {
        path: "dashboard",
        element: (
          <AuthGuard>
            <Statistics />
          </AuthGuard>
        ),
      },
      {
        path: "deposit",
        element: (
          <AuthGuard>
            <Deposit />
          </AuthGuard>
        ),
      },
      {
        path: "deposit/credits_and_debits",
        element: (
          <AuthGuard>
            <CreditsAndDebits />
          </AuthGuard>
        ),
      },
      {
        path: "withdrawal",
        element: (
          <AuthGuard>
            <Withdrawal />
          </AuthGuard>
        ),
      },
      {
        path: "emails",
        element: (
          <AuthGuard>
            <Emails />
          </AuthGuard>
        ),
      },
      {
        path: "emails/:emailId",
        element: (
          <AuthGuard>
            <EmailInfo />
          </AuthGuard>
        ),
      },
      {
        path: "emails/dispatch",
        element: (
          <AuthGuard>
            <EmailDispatch />
          </AuthGuard>
        ),
      },
      {
        path: "emails/dispatch/:emailId/edit",
        element: (
          <AuthGuard>
            <EmailEditDispatch />
          </AuthGuard>
        ),
      },
      {
        path: "affiliates",
        element: (
          <AuthGuard>
            <Affiliates />
          </AuthGuard>
        ),
      },
      {
        path: "links",
        element: (
          <AuthGuard>
            <AffiliateLinks />
          </AuthGuard>
        ),
      },
      {
        path: "links/security",
        element: (
          <AuthGuard>
            <AffiliateSecurity />
          </AuthGuard>
        ),
      },
      {
        path: "links/withdrawal",
        element: (
          <AuthGuard>
            <AffiliateWithdrawal />
          </AuthGuard>
        ),
      },
      {
        path: "users",
        element: (
          <AuthGuard>
            <Users />
          </AuthGuard>
        ),
      },
      {
        path: "users/search",
        element: (
          <AuthGuard>
            <SearchUsers />
          </AuthGuard>
        ),
      },
      {
        path: "users/active",
        element: (
          <AuthGuard>
            <ActiveUsers />
          </AuthGuard>
        ),
      },
      {
        path: "users/:accountId",
        element: (
          <AuthGuard>
            <UserStatistics />
          </AuthGuard>
        ),
      },
      {
        path: "users/referral/:referralId",
        element: (
          <AuthGuard>
            <ReferralUserStatistics />
          </AuthGuard>
        ),
      },
      {
        path: "statistics",
        element: (
          <AuthGuard>
            <Statistics />
          </AuthGuard>
        ),
      },
      {
        path: "transaction",
        element: (
          <AuthGuard>
            <Transaction />
          </AuthGuard>
        ),
      },
      {
        path: "balance",
        element: (
          <AuthGuard>
            <Balance />
          </AuthGuard>
        ),
      },
      {
        path: "order",
        element: (
          <AuthGuard>
            <Order />
          </AuthGuard>
        ),
      },
      {
        path: "parameters",
        element: (
          <AuthGuard>
            <Parameters />
          </AuthGuard>
        ),
      },
      {
        path: "parameters/simulator",
        element: (
          <AuthGuard>
            <Simulator />
          </AuthGuard>
        ),
      },
      {
        path: "campaigns",
        element: (
          <AuthGuard>
            <Campaigns />
          </AuthGuard>
        ),
      },
      {
        path: "my-account",
        element: (
          <AuthGuard>
            <MyAccount />
          </AuthGuard>
        ),
      },
      {
        path: "login",
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        ),
      },
      {
        path: "register",
        element: (
          <GuestGuard>
            <Register />
          </GuestGuard>
        ),
      },
      {
        path: "register",
        element: (
          <GuestGuard>
            <Register />
          </GuestGuard>
        ),
      },
      {
        path: "confirmation-email",
        element: (
          <GuestGuard>
            <ConfirmationEmailCode />
          </GuestGuard>
        ),
      },
      {
        path: "confirmation-2fa",
        element: (
          <GuestGuard>
            <Confirmation2FCode />
          </GuestGuard>
        ),
      },
      {
        path: "password-recovery",
        element: (
          <GuestGuard>
            <PasswordRecovery />
          </GuestGuard>
        ),
      },
      {
        path: "confirmation-password-code",
        element: (
          <GuestGuard>
            <ConfirmationPasswordCode />
          </GuestGuard>
        ),
      },
      {
        path: "change-password",
        element: (
          <GuestGuard>
            <ChangePassword />
          </GuestGuard>
        ),
      },
      {
        path: "404",
        element: (
          <GuestGuard>
            <Error404 />
          </GuestGuard>
        ),
      },
      {
        path: "500",
        element: (
          <GuestGuard>
            <Error500 />
          </GuestGuard>
        ),
      },
      {
        path: "*",
        element: (
          <GuestGuard>
            <NotFound />
          </GuestGuard>
        ),
      },
    ],
  },
];

export default routes;
