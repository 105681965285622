import { toast } from "react-toastify";
import { isMobile } from "react-device-detect";

export const notifySuccess = (message, time = 1500) => {
  toast.success(message, {
    closeButton: true,
    style: {
      backgroundColor: "#28a74585",
      color: "white",
      fontSize: isMobile ? 10 : 14,
      height: isMobile ? 50 : null,
    },
    progressStyle: { backgroundColor: "#006400" },
    pauseOnFocusLoss: false,
    pauseOnHover: false,
    autoClose: time,
    position: isMobile ? "top-center" : "bottom-right",
  });
};

export const notifyError = (message, time = 1500) =>
  toast.error(message, {
    closeButton: true,
    style: {
      backgroundColor: "#dc354585",
      color: "white",
      fontSize: isMobile ? 10 : 14,
      height: isMobile ? 50 : null,
    },
    progressStyle: { backgroundColor: "#8B0000" },
    pauseOnFocusLoss: false,
    pauseOnHover: false,
    autoClose: time,
    position: isMobile ? "top-center" : "bottom-right",
  });
