import React, { useEffect, useState } from "react";
import PixIcon from "@mui/icons-material/Pix";
import CurrencyBitcoinIcon from "@mui/icons-material/CurrencyBitcoin";

import useAuth from "../../hooks/useAuth";
import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import WithdrawForm from "../../../components/Withdraw/WithdrawForm";
import {
  partneRunrealisedBalanceRequest,
  partnerBalanceRequest,
} from "../../services/PartnerService";
import WithdrawListTable from "../../../components/Withdraw/WithdrawListTable";
import RevenueIcon from "../../../components/Icons/RevenueIcon";
import numeral from "numeral";
import Card1 from "../../../components/Card/Card1";
import MyBalanceInfo from "../../../components/Withdraw/MyBalanceInfo";
import WithdrawPixForm from "../../../components/Withdraw/WithdrawPixForm";

const tabs = [
  {
    label: "Saque cripto",
    type: "cripto",
    icon: CurrencyBitcoinIcon,
  },
  {
    label: "Saque pix",
    type: "pix",
    icon: PixIcon,
  },
];

export default function AffiliateWithdrawal() {
  const [balance, setBalance] = useState(0);
  const [runrealisedBalance, setRunrealisedBalance] = useState(0);
  const [currentTabType, setCurrentTabType] = useState(tabs[0].type);

  useEffect(() => {
    handleRequestBalance();
  }, []);

  const handleRequestBalance = async () => {
    try {
      const responseBalance = await partnerBalanceRequest();
      const responseRunrealised = await partneRunrealisedBalanceRequest();
      setBalance(responseBalance.data);
      setRunrealisedBalance(responseRunrealised.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={10}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Stack direction="row" spacing={1} sx={{ mb: 2 }}>
                {tabs.map(({ label, type, icon: Icon }) => (
                  <Button
                    key={type}
                    variant="contained"
                    size="large"
                    sx={{
                      backgroundColor: `rgba(255, 255, 255, ${
                        currentTabType === type ? "0.3" : "0.1"
                      })`,
                      ":hover": {
                        backgroundColor: `rgba(255, 255, 255, 0.2)`,
                      },
                    }}
                    onClick={() => setCurrentTabType(type)}
                    startIcon={<Icon />}
                  >
                    {label}
                  </Button>
                ))}
              </Stack>
              {currentTabType === "cripto" && (
                <WithdrawForm balance={balance} />
              )}
              {currentTabType === "pix" && (
                <WithdrawPixForm balance={balance} />
              )}
            </Grid>
            <Grid item xs={12} sx={{ p: 0 }}>
              <WithdrawListTable />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <MyBalanceInfo
            balance={balance}
            runrealisedBalance={runrealisedBalance}
          />
        </Grid>
      </Grid>
    </>
  );
}
