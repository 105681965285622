import {
  Button,
  FormControl,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";

import "react-datepicker/dist/react-datepicker.css";
import { INTERVALS } from "../../utils/constants";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { Modal } from "react-bootstrap";

const FiltersByInterval = ({
  interval,
  setInterval,
  setRangeInterval,
  customDateFrom,
  customDateTo,
  onChangeCustomDate,
  disableAllPeriod,
  onClick,
  sx,
}) => {
  const [openModalCustomDate, setOpenModalCustomDate] = useState(false);
  const [dateFrom, setDateFrom] = useState(new Date(Date.now()));
  const [dateTo, setDateTo] = useState(new Date(Date.now()));
  const selectedInterval = useMemo(
    () =>
      INTERVALS.find((s) => s.value === interval) || {
        value: "custom",
      },
    [interval]
  );

  useEffect(() => {
    if (!openModalCustomDate) {
      setDateFrom(new Date(Date.now()));
      setDateTo(new Date(Date.now()));
    }
  }, [openModalCustomDate]);

  return (
    <>
      <Modal
        show={openModalCustomDate}
        onHide={() => {
          setOpenModalCustomDate(false);
        }}
      >
        <Modal.Header>
          <Modal.Title>Data customizada</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormControl fullWidth sx={{ minWidth: 120 }}>
            <MobileDatePicker
              label="De"
              inputFormat="dd/MM/yyyy"
              value={dateFrom || customDateFrom}
              onChange={(e) => setDateFrom(e)}
              renderInput={(params) => <TextField {...params} />}
            />
          </FormControl>
          <FormControl fullWidth sx={{ minWidth: 120, mt:2 }}>
            <MobileDatePicker
              label="Até"
              inputFormat="dd/MM/yyyy"
              value={dateTo || customDateTo}
              onChange={(e) => setDateTo(e)}
              renderInput={(params) => <TextField {...params} />}
            />
          </FormControl>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              onChangeCustomDate({ dateFrom, dateTo });
              setOpenModalCustomDate(false);
            }}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      <FormControl sx={{ minWidth: 160, ...sx }}>
        <Select
          labelId="select-type"
          id="select-type"
          name="environment"
          onChange={(env) => {
            if (env.target.value === "custom") {
              return;
            }

            setInterval(env.target.value);
            setRangeInterval(env.target.value);
            if (onClick) {
              onClick(env.target.value);
            }
          }}
          value={selectedInterval.value}
        >
          {INTERVALS.map((i) => {
            if (disableAllPeriod && i.value === "all") {
              return null;
            }

            return (
              <MenuItem key={i.value} value={i.value}>
                {i.display}
              </MenuItem>
            );
          })}
          {onChangeCustomDate && (
            <MenuItem
              value="custom"
              onClick={() => {
                setInterval("custom");
                setRangeInterval("custom");
                setOpenModalCustomDate(true);
              }}
            >
              Customizado
            </MenuItem>
          )}
        </Select>
      </FormControl>
    </>
  );
};

export default FiltersByInterval;
