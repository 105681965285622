import React, { useEffect, useState } from "react";
import { Box, Divider, Paper, Stack, Typography } from "@mui/material";
import numeral from "numeral";

export default function MyBalanceInfo({ balance, runrealisedBalance }) {
  return (
    <Paper sx={{ borderRadius: 2 }}>
      <Stack direction={"column"}>
        <Box sx={{ p: 2 }}>
          <Typography fontWeight={500} fontSize={14}>
            Meu saldo
          </Typography>
        </Box>
        <Divider />
        <Stack direction={"column"} spacing={2} sx={{ p: 2 }}>
          <Box>
            <Typography
              sx={{ color: "#9C9C9C", mb: 1 }}
              fontWeight={500}
              fontSize={14}
            >
              Disponível para retirada
            </Typography>
            <Typography>{numeral(balance).format("0.00")} </Typography>
          </Box>
        </Stack>
        <Stack direction={"column"} spacing={2} sx={{ p: 2 }}>
          <Box>
            <Typography
              sx={{ color: "#9C9C9C", mb: 1 }}
              fontWeight={500}
              fontSize={14}
            >
              Pendente
            </Typography>
            <Typography>
              {numeral(runrealisedBalance).format("0.00")}{" "}
            </Typography>
          </Box>
        </Stack>
      </Stack>
    </Paper>
  );
}
