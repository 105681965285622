import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { getEmailsList } from "../services/EmailService.js";
import TableEmails from "../../components/Tables/TableEmails.js";

export default function Emails() {
  const navigate = useNavigate();
  const [emails, setEmails] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchEmails();
  }, []);

  const fetchEmails = async () => {
    try {
      setLoading(true);
      setEmails([]);
      const res = await getEmailsList();
      setEmails(res.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <>
      <TableEmails
        rows={emails}
        loading={loading}
        refreshData={fetchEmails}
        onAddButton={
          <Button
            variant="contained"
            sx={{ height: 36 }}
            onClick={() => navigate("/emails/dispatch")}
          >
            Novo dispato
          </Button>
        }
      />
    </>
  );
}
