import React, { useCallback, useEffect, useState } from "react";
import { format, subDays } from "date-fns";
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Link,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  IconButton,
  TableHead,
  TableRow,
  Typography,
  CircularProgress,
  useMediaQuery,
  Paper,
  TablePagination,
  Chip,
  Backdrop,
  FormControl,
  TextField,
  Select,
  MenuItem,
  Stack,
  SelectChangeEvent,
  Tooltip,
  Button,
  Modal,
  useTheme,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { Close, Mail, Refresh, Telegram } from "@mui/icons-material";
import { notifyError } from "../../utils/toast";
import labelsColors from "../../theme/labelsColors";
import translate from "../../utils/translatorUtil";
import { getWithdrawalsHistory } from "../../app/services/PartnerService";

const columns = ["ID", "Data", "Quantidade", "Status", "Descrição"];

const WITHDRAW_STATUS_LIST = ["COMPLETE", "IN_PROCESS", "CANCELED"];

const INIT_ACTIVE_WITHDRAW_FILTERS_DATA = {
  withdrawalStatus: WITHDRAW_STATUS_LIST[1],
  from: subDays(new Date(), 30),
  to: new Date(),
};

const INIT_METADATA = {
  numberOfElements: 0,
  totalElements: 0,
  totalPages: 0,
};

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      maxWidth: 550,
    },
    chip: {
      width: 18,
      height: 18,
      backgroundColor: "rgba(145, 158, 171, 0.24)",
      borderRadius: 0.51,
      alignContent: "center",
      justifyContent: "center",
      textAlign: "center",
    },
  })
);

const DepositListTable = () => {
  const { t } = useTranslation("dashboard");

  const classes = useStyles();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [activeWithdrawFiltersData, setActiveWithdrawFiltersData] = useState(
    INIT_ACTIVE_WITHDRAW_FILTERS_DATA
  );
  const [metadata, setMetadata] = useState(INIT_METADATA);
  const [page, setPage] = useState(0);

  const [withdrawals, setWithdrawals] = useState([]);
  const [loadingWithdraw, setLoadingWithdraw] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [messageModal, setMessageModal] = useState("");

  const getWithdrawals = async (page = 0) => {
    setLoadingWithdraw(true);
    try {
      const response = await getWithdrawalsHistory({ page, size: 100 });

      const { data, numberOfElements, totalElements, totalPages } =
        response.data;
      setMetadata({ numberOfElements, totalElements, totalPages });
      setWithdrawals(data);
      setLoadingWithdraw(false);
    } catch (err) {
      notifyError("Erro ao carregar lista de depósitos");
      setLoadingWithdraw(false);
      console.error(err);
    }
  };

  useEffect(() => {
    getWithdrawals();
  }, [activeWithdrawFiltersData]);

  const formatQueryParams = () => {
    let query = "";

    const { withdrawalStatus, from, to } = activeWithdrawFiltersData;

    query = query + `&withdrawalStatus=${withdrawalStatus}`;

    if (from) {
      query = query + `&from=${format(from, "yyyy-MM-dd")}`;
    }

    if (to) {
      query = query + `&to=${format(to, "yyyy-MM-dd")}`;
    }

    return query;
  };

  const changeSelect = (event) => {
    const { name, value } = event.target;
    console.log("DDDDDDD", {
      ...activeWithdrawFiltersData,
      [name]: value,
    });
    setActiveWithdrawFiltersData({
      ...activeWithdrawFiltersData,
      [name]: value,
    });
  };

  const handleChangeDate = (date, name) => {
    setActiveWithdrawFiltersData({
      ...activeWithdrawFiltersData,
      [name]: date,
    });
  };

  const handleChangePage = (page) => {
    setPage(page);
    getWithdrawals(page);
  };

  const showMessageModal = (message) => {
    setMessageModal(message);
    setOpenModal(true);
  };

  const closeMessageModal = () => {
    setMessageModal("");
    setOpenModal(false);
  };

  return (
    <>
      <Card>
        <CardHeader
          title={"Histórico de Comissão"}
          action={
            loadingWithdraw ? (
              <CircularProgress size={25} sx={{ mt: 0.5, mr: 1 }} />
            ) : (
              <IconButton aria-label="refresh" onClick={() => getWithdrawals()}>
                <Refresh />
              </IconButton>
            )
          }
        />
        <Divider />
        <Box
          sx={{
            backgroundImage: "background.primary",
            mt: 3,
            mr: 1,
            ml: 1,
            fontSize: "80%",
          }}
        >
          <Backdrop
            sx={{
              color: "#fff",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              height: "100%",
              justifyContent: "center",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={loadingWithdraw}
          >
            <CircularProgress color="inherit" sx={{ mt: 4 }} />
          </Backdrop>
          <TableContainer
            component={Paper}
            sx={{
              maxHeight: "auto",
            }}
          >
            <Table
              padding={isMobile ? "checkbox" : "normal"}
              size={`${isMobile ? "small" : null}`}
            >
              <TableHead sx={{ backgroundColor: "#f4f4f414" }}>
                <TableRow>
                  {columns.map((item) => (
                    <TableCell
                      align="center"
                      sx={{ fontSize: isMobile ? 8 : "0.71875rem" }}
                      key={item}
                    >
                      {item}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody
                sx={{
                  backgroundColor: "#ffffff0a",
                }}
              >
                {withdrawals.length > 0 ? (
                  withdrawals.map((item, index) => (
                    <TableRow
                      hover
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                        ".css-8boiwb-MuiTableCell-root.MuiTableCell-paddingCheckbox":
                          {
                            padding: isMobile && "0 0 0 10px !important",
                          },
                      }}
                      // onClick={() => openModalItemDetail(item)}
                    >
                      <TableCell align="center" size="small">
                        <Tooltip title={item.id}>
                          <Typography
                            color="textSecondary"
                            variant="subtitle2"
                            sx={{
                              fontSize: isMobile ? 7 : 14,
                            }}
                          >
                            {`${item.id.slice(0, 10)}...`}
                          </Typography>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center" size="small">
                        <Typography
                          color="textSecondary"
                          variant="subtitle2"
                          sx={{
                            fontSize: isMobile ? 7 : 14,
                          }}
                        >
                          {format(
                            new Date(item.createdAt),
                            "dd/MM/yyyy, HH:mm:ss"
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell align="center" size="small">
                        <Typography
                          color="textSecondary"
                          variant="subtitle2"
                          sx={{
                            fontSize: isMobile ? 7 : 14,
                          }}
                        >
                          {item.amount}
                        </Typography>
                      </TableCell>
                      <TableCell align="center" size="small">
                        <Chip
                          label={translate(item.transactionType)}
                          sx={{
                            ...labelsColors[item.transactionType],
                            fontWeight: 400,
                            fontSize: isMobile ? 7 : 14,
                          }}
                        />
                      </TableCell>
                      <TableCell align="center" size="small">
                        <Tooltip title={item.userFriendlyDescription}>
                          <Typography
                            color="textSecondary"
                            variant="subtitle2"
                            sx={{
                              fontSize: isMobile ? 7 : 14,
                            }}
                          >
                            <Link
                              href="#"
                              onClick={() =>
                                showMessageModal(item.userFriendlyDescription)
                              }
                            >
                              Ver nota
                            </Link>
                          </Typography>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell rowSpan={1} sx={{ color: "red" }}>
                      Não existem operações com os filtros selecionados
                    </TableCell>
                    <TableCell rowSpan={1} />
                    <TableCell rowSpan={1} />
                    <TableCell rowSpan={1} />
                    <TableCell rowSpan={1} />
                    <TableCell rowSpan={1} />
                    <TableCell rowSpan={1} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {withdrawals.length !== 0 && (
            <TablePagination
              rowsPerPageOptions={[metadata.numberOfElements]}
              component="div"
              count={metadata.totalElements}
              rowsPerPage={metadata.numberOfElements}
              page={page}
              onPageChange={(e, page) => handleChangePage(page)}
              sx={{ color: "#00A667" }}
            />
          )}
        </Box>
      </Card>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={openModal}
      >
        <Paper sx={{ p: 3, minWidth: 250 }} className={classes.paper}>
          <Stack direction={"column"} spacing={2}>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Typography
                fontSize={isMobile ? "0.6rem" : "1rem"}
                fontWeight="bold"
                textAlign={"center"}
              >
                Descrição
              </Typography>
              <IconButton
                aria-label="close"
                onClick={closeMessageModal}
                color="error"
                sx={{
                  color: (theme) => theme.palette.error[500],
                }}
              >
                <Close />
              </IconButton>
            </Stack>
            <Stack direction={"column"} spacing={2}>
              <Typography>{messageModal}</Typography>
            </Stack>
            <Stack
              direction={"row"}
              justifyContent="space-between"
              spacing={2}
              pt={3}
            >
              <Stack direction="row" spacing={1}>
                <IconButton
                  color="primary"
                  aria-label="telegram"
                  href="https://t.me/ebinexofficial"
                  target="_blank"
                >
                  <Telegram fontSize="small" />
                </IconButton>
                <IconButton
                  color="primary"
                  aria-label="email"
                  href="mailto:suporte@ebinex.com"
                >
                  <Mail fontSize="small" />
                </IconButton>
              </Stack>
            </Stack>
          </Stack>
        </Paper>
      </Modal>
    </>
  );
};

export default DepositListTable;
