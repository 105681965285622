import axios from "axios";
import { getBaseUrl } from "./BaseService";
import { convertToUSFormatString } from "../../utils";

export function getPartnerList({ dateFrom, dateTo }) {
  var finalUrl =
    getBaseUrl() +
    `/partner/links?dateFrom=${convertToUSFormatString(
      dateFrom
    )}&dateTo=${convertToUSFormatString(dateTo)}`;
  return axios.get(finalUrl);
}

export function signupPartner(
  { name, email, phone, password, referralId },
  callbackSucess,
  callbackError
) {
  return axios
    .post(getBaseUrl() + "/partner", {
      name,
      email,
      phone,
      password,
      referralId,
    })
    .then((res) => {
      window.localStorage.setItem("userRequest", JSON.stringify(res.data));
      callbackSucess(res.data);
    })
    .catch((err) => {
      callbackError(err);
      throw err;
    });
}

export function partnerBalanceRequest() {
  let config = {};
  if (localStorage.getItem("partnerAccountId")) {
    config = {
      headers: {
        accountId: localStorage.getItem("partnerAccountId"),
      },
    };
  }
  return axios.get(getBaseUrl() + "/partner/balance", config);
}

export function partneRunrealisedBalanceRequest() {
  let config = {};
  if (localStorage.getItem("partnerAccountId")) {
    config = {
      headers: {
        accountId: localStorage.getItem("partnerAccountId"),
      },
    };
  }
  return axios.get(getBaseUrl() + "/partner/unrealisedBalance", config);
}

export function withdrawalsRequestCode() {
  let config = {};
  if (localStorage.getItem("partnerAccountId")) {
    config = {
      headers: {
        accountId: localStorage.getItem("partnerAccountId"),
      },
    };
  }
  return axios.post(getBaseUrl() + "/partner/withdrawals/requestCode", config);
}

export function getWithdrawalsHistory({ page, size }) {
  let config = {};
  if (localStorage.getItem("partnerAccountId")) {
    config = {
      headers: {
        accountId: localStorage.getItem("partnerAccountId"),
      },
    };
  }
  return axios.get(
    getBaseUrl() +
      `/partner/transactions/history?page=${page}&size=${size}&sortAttributeName=createdAt&sortMode=DESC`,
    config
  );
}

export function withdrawalsRequest({
  asset,
  withdrawalAddressId,
  amount,
  validationCode,
}) {
  let config = {};
  if (localStorage.getItem("partnerAccountId")) {
    config = {
      headers: {
        accountId: localStorage.getItem("partnerAccountId"),
      },
    };
  }
  return axios.post(
    getBaseUrl() + "/partner/withdrawals",
    {
      asset,
      withdrawalAddressId,
      amount,
      validationCode,
    },
    config
  );
}

export function getPartnerStatisticsByDate({ dateFrom, dateTo }) {
  var finalUrl =
    getBaseUrl() +
    `/partner/statistics/byDates?dateFrom=${convertToUSFormatString(
      dateFrom
    )}&dateTo=${convertToUSFormatString(dateTo)}`;
  return axios.get(finalUrl);
}
