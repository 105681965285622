import axios from "axios";

function getBaseUrl() {
  return process.env.REACT_APP_API_BASE_URL;
}

export function requestValidateToken(token, successCallback, errorCallBack) {
  var bodyFormData = new FormData();
  bodyFormData.append("token", token);
  return axios
    .post(getBaseUrl() + "/auth/validateToken", bodyFormData)
    .then((res) => successCallback())
    .catch((err) => errorCallBack());
}

export async function login(
  username,
  password,
  successCallback,
  errorCallback
) {
  try {
    const res = await axios.post(getBaseUrl() + "/auth/login", {
      email: username,
      password: password,
    });
    if (res) {
      successCallback(res.data);
    }
  } catch (err) {
    console.log("login error", err);
    errorCallback(err);
  }
}

export function signup(username, password, context) {
  return axios
    .post(getBaseUrl() + "/users/user-requests", {
      email: username,
      password: password,
    })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
}

export function resetPassword(username, context) {
  var bodyFormData = new FormData();
  bodyFormData.append("email", username);
  bodyFormData.append("context", context);

  return axios
    .post(getBaseUrl() + "/user/resetPassword", bodyFormData)
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
}

export function confirmPassword(context, username, password, generatedToken) {
  var bodyFormData = new FormData();
  bodyFormData.append("context", context);
  bodyFormData.append("email", username);
  bodyFormData.append("password", password);
  bodyFormData.append("generatedToken", generatedToken);

  return axios
    .post(getBaseUrl() + "/user/confirmPassword", bodyFormData)
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
}

export async function verifyEmailCode(username, code) {
  return axios
    .post(getBaseUrl() + "/users/user-requests/submit", {
      id: username,
      code,
    })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
}

export async function verifyPasswordCode(email, code) {
  return axios
    .post(getBaseUrl() + "/users/password-recovery/verify", {
      userEmail: email,
      code,
    })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
}

export async function resendCode(username) {
  return axios
    .post(getBaseUrl() + "/users/user-requests/renovate", {
      id: username,
    })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
}

export async function passwordRecovery(email) {
  return axios
    .post(getBaseUrl() + "/users/password-recovery", {
      userEmail: email,
    })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
}

export async function passwordReset(email, code, newPassword) {
  return axios
    .post(getBaseUrl() + "/users/password-recovery/submit", {
      userEmail: email,
      code,
      newPassword,
    })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
}

export async function passwordResetVerify(userEmail, code, requestId) {
  return axios
    .post(getBaseUrl() + "/users/password-recovery/verify", {
      userEmail,
      requestId,
      code,
    })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
}
