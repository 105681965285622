import React, { useState, useEffect } from "react";
import axios from "axios";

import { requestValidateToken, login } from "../services/AuthService";
import { formatErrorMessage } from "../facades/formatError";
import { getAccounts, getUser } from "../services/UserService";
import { signupPartner } from "../services/PartnerService";

export const AppContext = React.createContext({});

const AppContextProvider = (props) => {
  const [reRender, setReRender] = useState(false);
  const [user, setUser] = useState({});
  const [accounts, setAccounts] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isAffiliate, setIsAffiliate] = useState(false);

  console.log("AppContextProvider");

  function getAccountId() {
    return localStorage.getItem("accountId");
  }

  function setAccountId(accountId) {
    return localStorage.setItem("accountId", accountId);
  }

  function getToken() {
    return localStorage.getItem("token");
  }

  function setToken(token) {
    localStorage.setItem("token", token);
  }

  function getValidToken() {
    const isValid = localStorage.getItem("validToken") === "true";
    console.log("isValid", isValid);
    return isValid;
  }

  function setValidToken(validToken) {
    localStorage.setItem("validToken", validToken);
  }

  function reRenderComponent() {
    setReRender(reRender === true ? false : true);
  }

  function validateToken() {
    console.log("validateToken()");
    if (!getToken()) {
      console.log("getToken");
      setValidToken(false);
      return false;
    }
    return requestValidateToken(
      getToken(),
      () => {
        console.log("requestValidateToken return sucess");
        return true;
      },
      () => {
        setValidToken(false);
        setToken("");
        reRenderComponent();
        return false;
      }
    );
  }

  function resetAuthState() {
    setUser({});
    setToken("");
    setValidToken(false);
    reRenderComponent();
    window.location.href = "/login";
  }

  async function logar(username, password, errorCallBack) {
    await login(
      username,
      password,
      async (res) => {
        setToken("Bearer " + res.token);
        setUser(res.user);
        setAccounts(res.accounts);

        checkAccounts(res.accounts);
        await initialize(errorCallBack);
        reRenderComponent();
      },
      errorCallBack
    );
  }

  function partnerRegister(data, errorCallBack) {
    signupPartner(
      data,
      async (res) => {
        console.log("res", res);
        return (window.location.href = "/confirmation-email");
      },
      errorCallBack
    );
  }

  function setAxiosInterceptors() {
    console.log("ooooooooooooooooooo");
    axios.defaults.headers.common["Authorization"] = getToken();
    axios.defaults.headers.common["accountId"] = getAccountId();
    // Add a 401 response interceptor
    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        console.log(error);
        if (
          error.response?.status === 401 &&
          error.response?.data?.message !== "Invalid2faCodeException"
        ) {
          if (localStorage.getItem("token")) {
            localStorage.removeItem("token");
          }

          setValidToken(false);
          reRenderComponent();
        }
        throw formatErrorMessage(error);
      }
    );
  }

  function logoutHandler() {
    resetAuthState();
  }

  async function initialize(callback) {
    const valid = await validateToken();

    if (!valid) return;

    const userRes = await getUser();
    const accountsRes = await getAccounts();

    const _accounts = accountsRes.data;
    setUser(userRes.data);
    setAccounts(_accounts);

    checkAccounts(_accounts, callback);

    setValidToken(true);
  }
  useEffect(() => {
    initialize();
  }, []);

  function checkAccounts(accounts, callback) {
    const adminAccount = accounts.find((account) => {
      return account.userRole === "ADMIN";
    });

    const addiliateAccount = accounts.find((account) => {
      return account.userRole === "PARTNER";
    });

    const accountsList = [];

    if (!adminAccount || !addiliateAccount) {
      if (callback) {
        callback();
      }
      setValidToken(false);
    }

    if (adminAccount) {
      accountsList.push(adminAccount);
      setIsAdmin(true);
      console.log("adminAccount", adminAccount);
    }
    if (addiliateAccount) {
      accountsList.push(addiliateAccount);
      console.log("partnerAccount", addiliateAccount);
      setIsAffiliate(true);
    }

    if (accountsList.length > 0) {
      setAccountId(accountsList[0].id);
    }
  }

  setAxiosInterceptors();

  return (
    <AppContext.Provider
      value={{
        user,
        accounts,
        isAdmin,
        isAffiliate,
        isLoggedIn: getValidToken,
        loginHandler: logar,
        logoutHandler: logoutHandler,
        partnerRegisterHandler: partnerRegister,
        contextConfig: props.contextConfigObject,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
